import React, {useState, useEffect, useContext} from 'react';
import ReactDOM from "react-dom";
import {isMobileOnly} from 'react-device-detect';
import {get_data_fetch} from "./func_fetch";
import {func_getCookie} from "./func_getCookie";
import Moment from "node_modules_path/moment";
import nodata from "../../img/nodata.png";
import loading_image from "../../img/loading.svg";


export function VendorBarContractLayout(props) {
    //
    // const usersContext = useContext(Consumer);
    // const { data, setData } = usersContext;

    //country and cities data
    const [data, setData] = useState([]);
    const [index, setIndex] = useState(0);
    const [previous, setPrevious] = useState([]);
    const [next, setNext] = useState([]);
    const [count, setCount] = useState([]);
    const [loading, setLoading] = useState(true);
    const [url_prp, setUrl_prp] = useState("");
    const [changeDataSelf, setChangeDataSelf] = useState(false);
    let url_link = "http://" + window.location.host;
    let offset = 10;

    useEffect(() => {
        (async () => {
            setLoading(true);
            //api data
            let data_list = await get_data_fetch(
                url_link + '/api/quot_contract_offer_list/',
                "GET",
                {index: index}
            );
            setLoading(false);
            setData(data_list.results);
            setCount(data_list.count);
            setPrevious(data_list.previous);
            setNext(data_list.next);
        })();
    }, []);

    useEffect(() => {
        let props_url = `&property__country=${props.url_data.property__country}` +
            `&property__city=${props.url_data.property__city}`;

        if (props_url != url_prp && typeof props.search_data.results !== 'undefined') {
            setData(props.search_data.results);
            setCount(props.search_data.count);
            setPrevious(props.search_data.previous);
            setNext(props.search_data.next);
            setIndex(0);
            setUrl_prp(
                `&property__country=${props.url_data.property__country}` +
                `&property__city=${props.url_data.property__city}`
            )
            setChangeDataSelf(false);

            if (props.search_data.results.length == 0) {
                setData([])
                setCount(0);
            }
        }
    }, [props]);


    async function get_data(prop, action) {
        if (action != null) {
            setLoading(true);
            let url = new URL(action);
            let next_offset = url.searchParams.get("offset");
            setIndex(next_offset / offset);
            let data_list = await get_data_fetch(
                action == null ? "#" : action,
                "GET",
                {index: index}
            );
            setLoading(false);
            setData(data_list.results);
            setCount(data_list.count);
            setPrevious(data_list.previous);
            setNext(data_list.next);
            setChangeDataSelf(true);
        }
    }

    function pagination() {
        let cur_index = 0;
        let table = [];
        for (let i = 0; i < count; i = i + offset) {
            table.push(
                <li key={i} className={cur_index == index ? "page-item ml-1 mr-1 active" : "page-item ml-1 mr-1"}>
                    <div className="page-link" onClick={() => {
                        get_data(null, url_link + '/api/quot_contract_offer_list/?offset=' + i + url_prp)
                    }}>{cur_index + 1}</div>
                </li>)
            cur_index++;
        }
        return table
    }

    function desktopLayout(item) {
        return (
            <section className="card mt-3 card_custom_radius2" key={item.id}>
                <div className="member_price_bk p-1">
                    {/*<span className="float-left pl-4">{item.id}</span>*/}
                    <span className="float-right pr-4">
                        {Moment(item.created_at).format('YYYY-MM-DD hh:mm a')}
                    </span>
                </div>
                <div className="card-body body_bk_color_white">
                    <div className="row">
                        <div className="col">
                            <div className="title_size">{gettext("Property")}</div>
                            <div>{item.property.name}</div>
                        </div>
                        <div className="col">
                            <div className="title_size">{gettext("Location")}</div>
                            <div>{item.property.country.name} / {item.property.city.name}</div>
                        </div>
                        <div className="col">
                            <div className="title_size">Token</div>
                            <div>1</div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <div className="title_size">{gettext("Contract Period")}</div>
                            <div>{item.date_from} - {item.date_to}</div>
                        </div>
                        <div className="col">
                            <a className="btn owner_btn width_100"
                               href={"/vendor/quot_contract_lists/detail/" + item.id}>{gettext("Detail")}</a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    function mobileLayout(item) {
        return (
            <section className="card mt-3 card_custom_radius2" key={item.id}>
                <div className="member_price_bk p-1">
                    {/*<span className="float-left pl-4">{item.id}</span>*/}
                    <span className="float-right pr-4">
                         {Moment(item.created_at).format('YYYY-MM-DD hh:mm a')}
                    </span>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col">{gettext("Property")}</div>
                        <div className="col">{item.property.name}</div>
                    </div>
                    <div className="row">
                        <div className="col">{gettext("Location")}</div>
                        <div className="col">{item.property.country.name} / {item.property.city.name}</div>
                    </div>
                    <div className="row">
                        <div className="col">{gettext("Start date")}</div>
                        <div className="col">{item.date}</div>
                    </div>
                    <div className="row">
                        <div className="col">Token</div>
                        <div className="col">1</div>
                    </div>
                    <div className="row">
                        <div className="col"></div>
                        <div className="col">
                            <a className="btn owner_btn shadow" href={"/vendor/quot_lists/detail/" + item.id}>
                                {gettext("Detail")}
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <>
            <div>
                {data.length > 0 && data.map(
                    (item) => {
                        return (isMobileOnly ?  item.property&&mobileLayout(item) :  item.property&&desktopLayout(item))
                    })
                }
                {
                    data.length == 0 && <div className="text-center p-3"><img src={nodata} height="50px"/>
                        <div>{gettext("No data")}</div>
                    </div>
                }
                <ul className="pagination justify-content-center mt-3">
                    <li className="page-item">
                        <div className="page-link" aria-label="Previous" onClick={() => {
                            get_data(null, previous)
                        }}>
                            <span aria-hidden="true">&laquo;</span>
                        </div>
                    </li>
                    {pagination()}
                    <li className="page-item">
                        <div className="page-link" aria-label="Next" onClick={() => {
                            get_data(null, next)
                        }}>
                            <span aria-hidden="true">&raquo;</span>
                        </div>
                    </li>
                </ul>
            </div>
            {loading && <div className="text-center"><img src={loading_image}/></div>}
        </>
    )
}




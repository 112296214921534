import {func_getCookie} from "./func_getCookie";


const get_data_fetch = async (url, post, parameter) => {
    let para_data = parameter != null && JSON.stringify(parameter)
    const rawResponse = await fetch(url, {
        method: post,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "X-CSRFToken": func_getCookie('csrftoken')
        },
        body: post === "POST" ? para_data : null,
    });
    let megs = await rawResponse.json();
    if (rawResponse.status === 400) {
        megs.map((item) => {
            alert(item);
        })
    }
    return megs;
};

export {get_data_fetch}

// const func_fetch = async(text) => {
//
//     let fetchData = await fetch('http://127.0.0.1:8000/api/search',{
//           method: 'POST',
//           headers: {
//               'Content-Type': 'application/json',
//               "X-CSRFToken": func_getCookie('csrftoken')
//           },
//           body:JSON.stringify({name:text})
//         })
//         .then(function (response) {
//             return response.json();
//         }).then(function (data) {
//             console.log("data get");
//             return data;
//         });
//     return fetchData;
// };
//
// export { func_fetch }
//
//
// const func_fetch_api = async(url) => {
//
//     let fetchData = await fetch(url,{
//           method: 'GET',
//           headers: {
//               'Content-Type': 'application/json',
//               "X-CSRFToken": func_getCookie('csrftoken')
//           },
//           //body:JSON.stringify(props)
//         })
//         .then(function (response) {
//             return response.json();
//         }).then(function (data) {
//             return data;
//         });
//     return fetchData;
// };
//
// export { func_fetch_api }
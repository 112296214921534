import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {get_data_fetch} from "./func_fetch";
import {func_getCookie} from "./func_getCookie";
import Moment from "node_modules_path/moment";


let select_current_country = $("select[name='country']").val();
let select_current_city = $("select[name='city']").val();

function SelectPlaceLayout() {

    //service data
    const [country_data, setcountry_data] = useState(0);
    const [current_service, setcurrent_country] = useState(0);

    //sub service data
    const [sub_city_data, setSub_city_data] = useState(0);
    const [current_sub_city, setcurrent_sub_city] = useState(0);

    let url_link = "//" + window.location.host;

    useEffect(() => {
        (async () => {
            //service data
            let country_data = await get_data_fetch('/api/location/', "GET",);
            setcountry_data(country_data);

            select_current_country !== "" && setSub_city_data(country_data.filter(item => item.id === parseInt(select_current_country))[0]["cities"]);
            setcurrent_country(select_current_country);
            setcurrent_sub_city(select_current_city);

        })();
    }, []);


    async function select_change(event) {
        if (event.target.value == "") {
            setcurrent_country("");
            setSub_city_data([]);
        } else {
            setcurrent_country(parseInt(event.target.value));
            setSub_city_data(country_data.filter(item => item.id === parseInt(event.target.value))[0]["cities"]);
        }
        setcurrent_sub_city("")
    }

    function CountrySelect() {
        return (
            <select className="form-group form-control btn-group" name="country" id="id_country"
                    value={current_service}
                    onChange={select_change}>
                <option value="">{gettext("Country")}</option>
                {country_data && country_data.map((item, index) =>
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>)}
            </select>
        )
    }


    function SubCitySelect() {
        return (
            <select className="form-control btn-group" name="city" id="id_city"
                    value={current_sub_city}
                    onChange={(event) => setcurrent_sub_city(event.target.value)}>
                <option value="">{gettext("Cities")}</option>
                {sub_city_data && sub_city_data.map((item, index) =>
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>)}
            </select>
        )
    }

    //form submit action
    // function handleSubmit(event) {
    //     //event.preventDefault();
    //     console.log(event.target);
    //     const data = new FormData(event.target);
    // }

    return (
        <>
            <label htmlFor="id_country">{gettext("Country")} :</label>
            {CountrySelect()}
            <label htmlFor="id_city">{gettext("City")} :</label>
            {SubCitySelect()}
        </>
    )
}

let element = document.getElementById('property_add');

if (typeof (element) != 'undefined' && element != null) {
    let select_country = document.getElementsByName("select");
    let select_city = document.getElementsByName("select");

    let parent_el = document.getElementById('property_add');
    let child_el = parent_el.getElementsByTagName("p");
    child_el[5].remove();

    $("select[name='country']").val(select_current_country);
    $("select[name='city']").val(select_current_city);

    ReactDOM.render(<SelectPlaceLayout/>, child_el[4]);
}



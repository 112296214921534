import React, {useState, useEffect, useContext} from 'react';
import ReactDOM from "react-dom";
import {isMobileOnly} from 'react-device-detect';
import nodata from "../../img/nodata.png";
import loading_image from "../../img/loading.svg";
import {get_data_fetch} from "./func_fetch";
import {VendorBarLayout} from "./vendor_quot_apply_lists";


function SearchButtonVendorLayout() {

    // const usersContext = useContext(Consumer);
    // const { setData } = usersContext;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    //country data
    const [country_data, setcountry_data] = useState(0);
    const [current_country, setcurrent_country] = useState("");

    //cities data
    const [city_data, setcity_data] = useState(0);
    const [current_city, setcurrent_city] = useState("");

    //service data
    const [service_data, setservice_data] = useState(0);
    const [current_service, setcurrent_service] = useState("");

    //contract
    const [iscontract, setiscontract] = useState(false);

    const [api_url_link, setApi_url_link] = useState({
        "property__country": "",
        "property__city": "",
        "service__sort_no": ""
    });

    let url_link = "http://" + window.location.host;

    useEffect(() => {
        (async () => {
            let url = new URL(window.location.href);
            // let url_country = url.searchParams.get("country");
            // let url_district = url.searchParams.get("district");
            // let url_service_type = url.searchParams.get("service_type");

            //api data
            let data_list = await get_data_fetch( '/api/location/', "GET", null);

            data_list.unshift({
                "cities": [],
                "id": "",
                "name": gettext("Country")
            });
            //setData(data_list);

            //country data and select the field from url parameter
            setcountry_data(data_list);

            //service data
            let service_data = await get_data_fetch('/api/services/', "GET", null);
            setservice_data(service_data);

            setcurrent_country("");
            setcurrent_city("");
            setcurrent_service("");

        })();
    }, []);


    function select_change(event) {
        console.log(country_data);
        console.log(event.target.value);
        if (event.target.value != "") {
            let city_data = country_data.filter((item) => item.id === parseInt(event.target.value))[0];
            setcity_data(city_data["cities"]);
            setcurrent_country(parseInt(event.target.value));
        } else {
            setcity_data([]);
            setcurrent_country("");
        }
        setcurrent_city("");
    }

    function CountrySelect() {
        return (
            <select className="form-control btn-group" name="country"
                    value={current_country}
                    onChange={select_change}>
                {country_data && country_data.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select>
        )
    }

    function CitySelect() {
        return (
            <select className="form-control btn-group" name="district"
                    value={current_city}
                    onChange={(event) => setcurrent_city(event.target.value)}>
                <option value="">{gettext("Location")}</option>
                {city_data && city_data.map((item, index) =>
                    <option key={item.id} value={item.id} defaultValue={index === 0 && "selected"}>
                        {item.name}
                    </option>)}
            </select>
        )
    }

    function ServiceSelect() {
        return (
            <select className="form-control btn-group" name="service_type"
                    value={current_service}
                    onChange={(event) => setcurrent_service(event.target.value)}>
                <option value="">{gettext("Service")}</option>
                {service_data && service_data.map((item, index) =>
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>)}
            </select>
        )
    }

    //form submit action
    async function handleSubmit() {
        setLoading(true);
        setcurrent_country(current_country != "" ? parseInt(current_country) : current_country);
        setcurrent_city(current_city != "" ? parseInt(current_city) : current_city);
        setcurrent_service(current_service != "" ? parseInt(current_service) : current_service);

        //api data
        let data_list = await get_data_fetch(
            url_link + `/api/quot_offer_list/?property__country=${current_country}&property__city=${current_city}&parent_service__id=${current_service}`,
            "GET",
            {}
        );
        let data_list2 = await get_data_fetch(
            url_link + `/api/quot_contract_offer_list/?property__country=${current_country}&property__city=${current_city}`,
            "GET",
            {}
        );
        setData(data_list);
        setApi_url_link(
            {
                "property__country": current_country,
                "property__city": current_city,
                "service__sort_no": current_service
            }
        )
        setLoading(false);
        //window.history.pushState("", "", `?service__sort_no=${current_service}`);
    }

    function desktopLayout() {
        return (
            <div className="row align-items-center">
                <div className="col">{CountrySelect()}</div>
                <div className="col">{CitySelect()}</div>
                <div className="col">{ServiceSelect()}</div>
                <div className="col">
                    <button type="submit" className="btn search_btn width_100" onClick={handleSubmit}>
                        <i className="fas fa-search"/>{gettext("Search")}
                    </button>
                </div>
            </div>
        )
    }

    function mobileLayout() {
        return (
            <div className="align-items-center">
                <div className="p-3">{CountrySelect()}</div>
                <div className="p-3">{CitySelect()}</div>
                <div className="p-3">{ServiceSelect()}</div>
                <div className="p-3 text-right">
                    <button type="submit" className="btn search_btn float-none width_100" onClick={handleSubmit}>
                        <i className="fas fa-search"/>{gettext("Search")}
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div>
            {isMobileOnly ? mobileLayout() : desktopLayout()}
            {loading && <div className="text-center"><img src={loading_image}/></div>}
            <VendorBarLayout search_data={data} url_data={api_url_link}/>
        </div>
    )
}


let element = document.getElementById('vendor_quot_pool_bar');
if (typeof (element) != 'undefined' && element != null) {
    ReactDOM.render(<SearchButtonVendorLayout/>, document.getElementById('vendor_quot_pool_bar'));
}

// let element2 = document.getElementById('vendor_quot_pool_bar_search');
// if (typeof (element2) != 'undefined' && element2 != null) {
//     ReactDOM.render(<SearchButtonVendorLayout/>, document.getElementById('vendor_quot_pool_bar_search'));
// }


import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {get_data_fetch} from "./func_fetch";


function PaymentButtonLayout() {
    //country and cities data
    const [data, setData] = useState([]);

    //country data
    const [loading, setLoading] = useState(false);

    let url_link = "http://" + window.location.host;

    async function payment_tran_paypal() {
        setLoading(true);

        let param = {
            user: user_id,
            product: product_id,
            name: product_name,
            price: product_price,
            token: product_token,
            product_type: "token",
        }

        //create_order
        let data_order = await get_data_fetch(url_link + '/api/create_order/', "POST", param);

            document.querySelector("#id_amount").value =data_order.price;
            document.querySelector("#id_item_name").value=data_order.name;
            document.querySelector("#id_invoice").value= "token_"+ data_order.id.toString();
            document.querySelector("#paypalform > form").submit();
        // setData(data_list);
    }


    return (
        <div className="btn login_btn text-white">
            {!loading && <span onClick={payment_tran_paypal}>確定購買(PayPal)</span>}
            {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>}
            {loading && <span>Loading...</span>}
        </div>
    )
}

let element = document.getElementById('payment_button');
if (typeof (element) != 'undefined' && element != null) {
    ReactDOM.render(<PaymentButtonLayout/>, document.getElementById('payment_button'));
}




import React, {useState, useEffect, useRef} from 'react';
import ReactDOM from "react-dom";
import {get_data_fetch} from "./func_fetch";
import Moment from "node_modules_path/moment";
import nodata from "../../img/nodata.png";
import loading_image from "../../img/loading.svg";


function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, []);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}


function ChatLayout() {
    //country and cities data
    const [chat_group, setChat_group] = useState([]);
    const [chat_line, setChat_line] = useState([]);
    const [current_group, setCurrent_group] = useState({});
    const [loading_group, setLoading_group] = useState(true);
    const [loading_chat_line, setLoading_chat_line] = useState(true);
    const [msg, setMsg] = useState("");
    const [delay, setDelay] = useState(10000);
    const [isRunning, setIsRunning] = useState(true);
    const [is_edit, setIs_edit] = useState(1);
    let url_link = "http://" + window.location.host;

    useEffect(() => {
        let aaaa =
            (async () => {
                setLoading_group(true);
                //api data
                let data_chat_group = await get_data_fetch(
                    url_link + '/api/chat-rooms/get_chat_room_by_user/',
                    "GET"
                );
                if (data_chat_group.length > 0) {
                    setChat_group(data_chat_group);
                    let data_chat_line = await get_data_fetch(
                        url_link + `/api/chat-rooms/${data_chat_group[0].id}/get_chat_room_line/`,
                        "GET"
                    );
                    setLoading_chat_line(true);
                    setCurrent_group(data_chat_group[0]);
                    setChat_line(data_chat_line);
                    setLoading_chat_line(false);
                    $('#chat_box').animate({scrollTop: 99999}, 'slow');
                }
                setLoading_group(false);
            })();
    }, []);

    useInterval(() => {
        get_current_group_data(null).then();
        check_new_msg_group().then();
    }, isRunning ? parseInt(delay) : null);

    function set_current_group_data(id) {
        let cur_group = chat_group.filter(item => item.id == id);
        setCurrent_group(cur_group[0]);
        setLoading_chat_line(true);
        setIs_edit(cur_group[0]["state"]);
        get_current_group_data(id).then($('#chat_box').animate({scrollTop: 99999}, 'slow'));
    }

    async function get_current_group_data(id) {
        let idd = current_group.id;
        if (id != null) {
            idd = id;
        }
        if (idd != null) {
            let data_chat_line = await get_data_fetch(
                url_link + `/api/chat-rooms/${idd}/get_chat_room_line/`,
                "GET"
            );
            setChat_line(data_chat_line);
            setLoading_chat_line(false);
        }
    }

    async function check_new_msg_group() {
        let data_chat_group = await get_data_fetch(
            url_link + '/api/chat-rooms/get_chat_room_by_user/',
            "GET"
        );
        if (data_chat_group.length > 0) {
            setChat_group(data_chat_group);
        }
    }


    async function sendmsg() {
        if (msg.length > 0) {
            await get_data_fetch(
                url_link + `/api/chat-rooms/${current_group.id}/send_chat/`,
                "POST",
                {"msg": msg}
            ).then(setMsg(""));
            get_current_group_data(null).then($('#chat_box').animate({scrollTop: 99999}, 'slow'));
        }
    }


    function chat_room_list() {
        return (
            <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="card mt-3 card_custom_radius2">
                    <div className="member_title_bk p-1 pl-2">{gettext("New message")}</div>
                    <div className="card-body p-0" id="chat_list">
                        {chat_group.length > 0 && chat_group.map((item) => {
                            return (
                                <div
                                    className={item.id == current_group.id ?
                                        "chat_group_line modal-header media mb-3 mt-3 p-2 card_custom_radius2 active" + (item.state >= 2 ? " done" : "") :
                                        "chat_group_line modal-header media mb-3 mt-3 p-2 card_custom_radius2" + (item.state >= 2 ? " done" : "")}
                                    key={item.id} onClick={() => {
                                    set_current_group_data(item.id);
                                }}>
                                    <div className="align-self-center w-100">
                                        <div className="row ml-0 mr-0">
                                            <div className="col-3 pr-0">
                                                <img className="chat_icon" src={item.receiver.thumbnail_name}/>
                                            </div>
                                            <div className="col-9 p-0">
                                                <span>{item.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        item.id != current_group.id &&
                                        <div className="align-self-center badge badge-pill badge-danger">
                                            {item.is_read > 0 && item.is_read}
                                        </div>
                                    }
                                </div>
                            )
                        })}
                        {loading_group && <div className="text-center"><img src={loading_image}/></div>}
                    </div>
                </div>
            </div>
        )
    }


    function chat_room_detail() {
        return (
            <div className="col-xs-12 col-sm-12 col-md-8">

                <div className="row">
                    <div className="col">
                        <h3 className="vendor_main_title_color2">{gettext("Inbox")}</h3>
                    </div>
                    <div className="col align-self-center">
                        <a className="btn vendor_btn" href={url_link + "/member"}>
                            {gettext("Back")}
                        </a>
                    </div>
                </div>
                <div className="card mt-3 card_custom_radius2" id="chat_list_detail">
                    <div
                        className="chat_bar text-center p-1 pl-2">{gettext("With")} {current_group.receiver.user_profile_name} {gettext("chat")}</div>
                    <div className="card-body chat_box p-1" id="chat_box">
                        {
                            chat_line.length > 0 && chat_line.map((line) => {
                                if (line.is_sender) {
                                    return (
                                        <div className="media justify-content-end mb-3 mt-3" key={line.created_at}>
                                            <div className="mr-3">
                                                <div className="text-right">
                                                    {Moment(line.created_at).format('YYYY-MM-DD hh:mm a')}
                                                </div>
                                                <div className="chat_vendor p-2">
                                                    {line.content}
                                                </div>
                                            </div>
                                            <img className="chat_icon"
                                                 src={line.user_image}/>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="media mb-3 mt-3" key={line.created_at}>
                                            <img className="chat_icon"
                                                 src={line.user_image}/>
                                            <div className="ml-3">
                                                <div>{Moment(line.created_at).format('YYYY-MM-DD hh:mm a')}</div>
                                                <div className="chat_vendor p-2">
                                                    {line.content}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                        {loading_chat_line && <div className="text-center"><img src={loading_image}/></div>}
                    </div>
                    {is_edit === 1 &&
                    <div className="chat_send_msg row">
                        <div className="col-xs-9 col-sm-9 col-md-9">
                            <textarea placeholder={gettext("enter message")} className="chat_input" value={msg}
                                      onChange={(events) => {
                                          var msg_strings = events.target.value;
                                          var check_string = msg_strings.substring(0, 1);
                                          if(check_string == '\n' || check_string == " ") {
                                              setMsg(msg_strings.substr(1));
                                          }else{
                                              setMsg(msg_strings);
                                          }
                                      }}/>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-md-3 align-self-center">
                            <div role="button" className="btn send_btn sendmsg"
                                 onClick={() => sendmsg()}>{gettext("Send")}
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    }

    function no_data_layout() {
        return (
            <div className="min-vh-50 text-center">

                <a className="btn vendor_btn" href={url_link + "/member"}>
                    {gettext("Back")}
                </a>
                <div>
                    {gettext("No data")}
                </div>
            </div>
        )
    }

    return (
        <>
            {chat_group.length > 0 && chat_room_list()}
            {chat_group.length === 0 && no_data_layout()}
            {typeof (current_group.receiver) != "undefined" && chat_group.length > 0 && chat_room_detail()}
        </>
    )
}

let element = document.getElementById('chat_room');
if (typeof (element) != 'undefined' && element != null) {
    ReactDOM.render(<ChatLayout/>, document.getElementById('chat_room'));
}
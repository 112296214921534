import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {isMobileOnly} from 'react-device-detect';
import {get_data_fetch} from "./func_fetch";
import nodata from "../../img/nodata.png";
import loading_image from "../../img/loading.svg";

function VendorSubmitLayout() {
    //country and cities data
    const [rank, setRank] = useState(0);
    const [comment, setComment] = useState("");
    const [editRank, setEditRank] = useState(false);
    const [submitForm, setSubmitForm] = useState(false);
    const [loading, setLoading] = useState(false);
    let url_link = "http://" + window.location.host;

    useEffect(() => {
        (async () => {
            console.log(token);
            console.log(applyform_id);
        })();
    }, []);

    async function submit_data() {
        setLoading(true);
        setSubmitForm(true);
        if (editRank) {
            let data_list = await get_data_fetch(url_link + "/api/quot_vendor_finish",
                "POST",
                {
                    applyform_id: applyform_id,
                    token: token,
                    rank: rank,
                    comment:comment
                }
            );
            document.querySelector("#vendor_apply_submit_finish > div > div > div.modal-header > button").click();
            window.location.reload();
        }
        setLoading(false);
    }


    function setRankBtn(val) {
        setEditRank(true);
        setRank(val);
    }

    return (
        <>
            <div className="modal" id="vendor_apply_submit_finish" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{gettext("Submit the service")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>{gettext("Rank")}:<span className="ml-3">{rank}</span>/5</div>
                            <div className="pl-3 pr-3 pb-3">
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(1)}>
                                    <i className={rank >= 1 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(2)}>
                                    <i className={rank >= 2 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(3)}>
                                    <i className={rank >= 3 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(4)}>
                                    <i className={rank >= 4 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(5)}>
                                    <i className={rank >= 5 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                            </div>
                            <div>
                                <textarea className="form-control" placeholder={gettext("comment")} value={comment} rows="3"
                                          onChange={(event) => setComment(event.target.value)}></textarea>
                            </div>
                            <div className="text-danger font-weight-bold">
                                {!editRank && submitForm && gettext("Please give a rank and comment")}
                            </div>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn submit_button"
                                    onClick={() => {
                                        !loading && submit_data()
                                    }}>{gettext("Confirm")}</button>
                            <button type="button" className="btn submit_button"
                                    data-dismiss="modal">{gettext("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" className="btn submit_button" data-toggle="modal"
                    data-target="#vendor_apply_submit_finish">
                {gettext("Confirm Finish")}
            </button>
            {loading && <div className="text-center"><img src={loading_image}/></div>}
        </>
    )
}

let element = document.getElementById('vendor_submit_finish');
if (typeof (element) != 'undefined' && element != null) {
    ReactDOM.render(<VendorSubmitLayout/>, document.getElementById('vendor_submit_finish'));
}


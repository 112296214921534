import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {isMobileOnly} from 'react-device-detect';
import {get_data_fetch} from "./func_fetch";
import {func_getCookie} from "./func_getCookie";
import Moment from "node_modules_path/moment";
import nodata from "../../img/nodata.png";
import loading_image from "../../img/loading.svg";
import {money_format} from "./money_format";
import fileDownload from "js-file-download";

function ContractListRecordsLayout() {
    //country and cities data
    const [data, setData] = useState([]);
    const [data_end, setData_end] = useState([]);
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [rank, setRank] = useState(0);
    const [comment, setComment] = useState("");
    const [editRank, setEditRank] = useState(false);
    const [submitForm, setSubmitForm] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(false);
    const [currentVendorItemId, setCurrentVendorItemId] = useState(false);

    const [itemId, setItemId] = useState(false);
    const [selected_item_id, setSelected_id] = useState(false);
    const [selected_form_id, setSelected_form_id] = useState(false);

    const [current_type, setCurrent_type] = useState();
    const [current_action, setCurrent_action] = useState("");
    const [count, setCount] = useState([]);
    const [previous, setPrevious] = useState([]);
    const [next, setNext] = useState([]);
    let url_link = "http://" + window.location.host;
    let offset = 10;
    const action_type = {"pending": 0, "trading": 1, "finish": 2, "cancel": 3}
    const date = new Date();
    const newDate = addDays(date, 90);
    useEffect(() => {
        (async () => {
            setLoading(true);
            let url = new URL(window.location.href);
            let action = url.searchParams.get("action");
            if (action != null) {
                setCurrent_action(action);
            } else {
                setCurrent_action("pending");
            }
            //api data
            let data_list = await get_data_fetch(
                url_link + '/api/contract_quot_pending_list/',
                "POST",
                {prop_id: current_id, state: action_type[action]}
            );
            let data_end_list = await get_data_fetch(
                url_link + '/api/contract_quot_deadline_list/',
                "POST",
                {prop_id: current_id, state: action_type[action]}
            );
            // if (data_list.count === 0) {
            //     data_list = await get_data_fetch(
            //         url_link + '/api/contract_quot_trading_list/',
            //         "POST",
            //         {prop_id: current_id, state: action_type[action]}
            //     );
            // }
            setCurrent_type(action_type[action]);
            setLoading(false);
            setData(data_list.results);
            setData_end(data_end_list.results);
            // setCount(data_list.count);
            // setPrevious(data_list.previous);
            // setNext(data_list.next);
        })();
    }, []);

    async function get_data(prop, action) {
        setLoading(true);
        let url = new URL(action);
        let next_offset = url.searchParams.get("offset");
        setIndex(next_offset / offset);
        let data_list = await get_data_fetch(
            action,
            "POST",
            {prop_id: current_id, state: prop == null ? action_type[current_action] : prop}
        );
        setLoading(false);
        setData(data_list.results);
    }

    async function accept_quotation(quotation_id, reply_form_id) {
        setLoading(true);
        let data_list = await get_data_fetch(
            url_link + '/api/contract_quot_list_accept',
            "POST",
            {prop_id: current_id, quotation_id: quotation_id, reply_form_id: reply_form_id}
        );
        setLoading(false);
        if (data_list.msg === "done") {
            location.reload();
        }
    }

    async function submit_data() {
        setLoading(true);
        setSubmitForm(true);
        if (editRank) {
            let data_list = await get_data_fetch(url_link + "/api/quot_owner_finish",
                "POST",
                {
                    quot_id: currentItemId,
                    token: token,
                    rank: rank,
                    comment: comment,
                    vendor_id: currentVendorItemId,
                }
            );
            document.querySelector("#owner_property_submit_finish > div > div > div.modal-header > button").click();
            location.href = '/owner/property_detail/' + currentItemId + '?action=finish';
        }
        setLoading(false);
    }

    function addDays(date, days) {
        const copy = new Date(Number(date));
        copy.setDate(date.getDate() + days);
        return copy
    }

    function contract_end_day(item_date_to) {
        let date_to = new Date(item_date_to);
        let today = new Date();
        let count_days = (date_to.getTime() - today.getTime()) / 1000 / 60 / 60 / 24;

        if (newDate.getDate() >= date_to.getDate()) {
            return <span className="vendor_main_title_color2">({Math.floor(count_days)}days)</span>;
        }
    }

    async function cancel_contract() {
        setLoading(true);

        let data_list = await get_data_fetch(url_link + "/api/contract_cancel",
            "POST",
            {
                itemId: itemId,
            }
        );
        if (data_list.msg === "error") {
            alert("Error");
            setLoading(false);
        }

        if (data_list.msg === "done") {
            setLoading(false);
            location.reload();
        }
    }

    async function renewwal_contract() {
        setLoading(true);

        let data_list = await get_data_fetch(url_link + "/api/contract_renewal",
            "POST",
            {
                renewalItemId: itemId,
            }
        );
        if (data_list.msg === "error") {
            alert("Error");
            setLoading(false);
        }

        if (data_list.msg === "done") {
            setLoading(false);
            location.reload();
        }
    }


    function fileDownloadFROMURL(item) {
        let file_url = window.location.origin + "/download_contract_vendor/" + item.id + "/" + item.file_name;

        return (
            item.file && <a className="btn file_btn" href={file_url} download={item.file_name}>
                {item.file_name}
            </a>
        )
    }


    function desktopDeadLineLayout(item) {
        return (
            <>
                <div className="card-body body_bk_color_white contract_layout" key={item.id}>
                    <div className="row">
                        <div className="col-2 title_size">{gettext("Contract")}</div>
                        <div className="col-7">
                            <span>{item.date_from} - {item.date_to}</span>
                            <span className="vendor_main_title_color3">({gettext("Contract had expired")})</span>
                        </div>
                        <div className="col-3">
                            <small>{Moment(item.created_at).format('YYYY-MM-DD hh:mm a')}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2 title_size">{gettext("File")}</div>
                        <div className="col-10">
                            {fileDownloadFROMURL(item)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2 title_size">{gettext("Remark")}</div>
                        <div className="col-10">{item.remark}</div>
                    </div>
                </div>
            </>
        )
    }


    function mobileDeadLineLayout(item) {
        return (
            <div key={item.id}>
                <div className="member_price_bk p-1">
                        <span>
                            <span className="vendor_main_title_color3">({gettext("Contract had expired")})</span>}
                        </span>
                    <span className="float-right pr-4">
                            {Moment(item.created_at).format('YYYY-MM-DD hh:mm a')}
                        </span>
                </div>

                <div className="card-body body_bk_color_white">
                    <div className="row">
                        <div className="col title_size">{gettext("Contract")}</div>
                        <div className="col">{item.date_from} - {item.date_to}{contract_end_day(item.date_to)}</div>
                    </div>
                    <div className="row">
                        <div className="col title_size">{gettext("File")}</div>
                        <div className="col">
                            {fileDownloadFROMURL(item)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col title_size">{gettext("Remark")}</div>
                        <div className="col">{item.remark}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div>
                {data_end.length > 0 && data_end.map((item) => {
                        return (
                            <section className="card mt-3 body_bk_color_dark_white" key={item.id}>
                                {isMobileOnly ? mobileDeadLineLayout(item) : desktopDeadLineLayout(item)}
                            </section>
                        )
                    })
                }
                {
                    data.length === 0 && data_end.length === 0 && <div className="text-center p-3"><img src={nodata} height="50px"/>
                        <div>No data</div>
                    </div>
                }
                {loading && <div className="text-center"><img src={loading_image}/></div>}
            </div>
        </>
    )
}

let element = document.getElementById('owner_contract_quot_list_records');
if (typeof (element) != 'undefined' && element != null) {
    ReactDOM.render(<ContractListRecordsLayout/>, document.getElementById('owner_contract_quot_list_records'));
}



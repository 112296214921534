import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {get_data_fetch} from "./func_fetch";


function SearchButtonLayout() {
    //country and cities data
    const [data, setData] = useState([]);

    //country data
    const [country_data, setcountry_data] = useState(0);
    const [current_country, setcurrent_country] = useState(0);

    //cities data
    const [city_data, setcity_data] = useState(0);
    const [current_city, setcurrent_city] = useState(0);

    //service data
    const [service_data, setservice_data] = useState(0);
    const [current_service, setcurrent_service] = useState(0);

    let url_link = "http://" + window.location.host;

    useEffect(() => {
        (async () => {
            let url = new URL(window.location.href);
            let url_country = url.searchParams.get("country");
            let url_district = url.searchParams.get("district");
            let url_service_type = url.searchParams.get("service_type");


            //api data
            let data_list = await get_data_fetch('/api/location/', "GET", null);
            setData(data_list);

            //country data and select the field from url parameter
            setcountry_data(data_list);

            //city data
            city_data_from_url_prama(url_country, url_district, data_list);

            //service data
            let service_data = await get_data_fetch('/api/services/', "GET", null);
            setservice_data(service_data);
            setcurrent_service(url_service_type == null ? "" : url_service_type);

        })();
    }, []);

    function city_data_from_url_prama(url_country, url_district, data) {
        if (url_country != null || url_country !== '' || url_country !== 0 || url_district != null) {
            let city_data_list = data.filter((item) => item.id === parseInt(url_country))[0];
            if (typeof city_data_list != "undefined") {
                setcity_data(city_data_list["cities"]);
            }
        }

        if (url_country != null) {
            setcurrent_country(url_country === 0 ? 0 : url_country);
        }

        if (url_district != null) {
            setcurrent_city(url_district === '' ? 0 : url_district);
        }
    }

    function select_change(event) {
        if (event.target.value !== '0') {
            let city_data = country_data.filter((item) => item.id === parseInt(event.target.value))[0];
            setcity_data(city_data["cities"]);
            setcurrent_country(parseInt(event.target.value));
        } else {
            setcity_data([]);
            setcurrent_country("");
        }
        setcurrent_city("");
    }


    function CountrySelect() {
        return (
            <select className="form-control btn-group" name="country"
                    value={current_country}
                    onChange={select_change}>
                <option value="0">{gettext("Country")}</option>
                {country_data && country_data.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select>
        )
    }

    function CitySelect() {
        return (
            <select className="form-control btn-group" name="district"
                    value={current_city}
                    onChange={(event) => setcurrent_city(Number.parseInt(event.target.value))}>
                <option value="">{gettext("City")}</option>
                {city_data && city_data.map((item, index) =>
                    <option key={item.id} value={item.id} defaultValue={index === 0 && "selected"}>
                        {item.name}
                    </option>)}
            </select>
        )
    }

    function ServiceSelect() {
        return (
            <select className="form-control btn-group" name="service_type"
                    value={current_service}
                    onChange={(event) => setcurrent_service(event.target.value)}>
                <option value="">{gettext("Service")}</option>
                {service_data && service_data.map((item, index) => {
                        if (current_city === 0 || current_city === "0" || current_city === "") {
                            return <option key={item.id} value={item.id}>{item.name}</option>
                        } else {
                            return city_data.map((city_item, index) => {
                                // select current city
                                if (city_item.id === Number.parseInt(current_city)) {
                                    // current city services
                                    return city_data[index]["services"].map((service_item) => service_item === item.id &&
                                        <option key={item.id} value={item.id}>{item.name}</option>);
                                }
                            });
                        }
                    }
                )}
            </select>
        )
    }

    return (
            <form action={url_link + "/service"} onSubmit={null}>
                {CountrySelect()}
                {CitySelect()}
                {ServiceSelect()}
                <button type="submit" className="btn search_btn">
                <i className="fas fa-search"/>
                {/*{gettext("Search")}*/}
                </button>
            </form>
        )
    }

    let element = document.getElementById('search_bar');
    let element2 = document.getElementById('search_bar_mobile');
    if(typeof (element) != 'undefined' && element != null) {
        ReactDOM.render(<SearchButtonLayout/>, document.getElementById('search_bar'));
    }
    if(typeof (element2) != 'undefined' && element2 != null) {
        ReactDOM.render(<SearchButtonLayout/>, document.getElementById('search_bar_mobile'));
    }




import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {get_data_fetch} from "./func_fetch";
import {func_getCookie} from "./func_getCookie";
import Moment from "node_modules_path/moment";


function SelectServiceLayout() {

    //service data
    const [service_data, setservice_data] = useState(0);
    const [current_service, setcurrent_service] = useState(0);

    //level1 service data
    const [sub_service_data, setSub_service_data] = useState(0);
    const [current_sub_service, setcurrent_sub_service] = useState(0);

    //level2 service data
    const [sub2_service_data, setSub2_service_data] = useState(0);
    const [current_sub2_service, setcurrent_sub2_service] = useState(0);

    let url_link = "http://" + window.location.host;

    useEffect(() => {
        (async () => {
            //service data
            let service_data = await get_data_fetch('/api/services/', "GET",);
            setservice_data(service_data);
            //setcurrent_service(service_id == null ? "" : service_id);
        })();
    }, []);


    async function select_change(event) {
        console.log(event.target.value)
        if(event.target.value == "") {
            setcurrent_service("");
            setSub_service_data([]);
        } else {
            setcurrent_service(parseInt(event.target.value));
            let data = await get_data_fetch( '/api/services/?service_id=' + parseInt(event.target.value), "GET",);
            setSub_service_data(data);
        }
        setcurrent_sub_service("")
    }

    async function select_change2(event) {
        console.log(event.target.value);
        if(event.target.value !== "") {
            setcurrent_sub_service(parseInt(event.target.value));
            let data = await get_data_fetch( '/api/services/?service_id=' + parseInt(event.target.value), "GET",);
            setSub2_service_data(data);
        }
    }


    function ServiceSelect() {
        return (
            <select className="form-group form-control btn-group" name="parent_service"
                    value={current_service}
                    onChange={select_change}>
                <option value="">{gettext("Select service type")}</option>
                {service_data && service_data.map((item, index) =>
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>)}
            </select>
        )
    }


    function Level1ServiceSelect() {
        return (
            <select className="form-control btn-group" name="service"
                    value={current_sub_service}
                    onChange={select_change2}>
                <option value="">{gettext("Select main service")}</option>
                {sub_service_data && sub_service_data.map((item, index) =>
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>)}
            </select>
        )
    }

    function Level2ServiceSelect() {
        return (
            <select className={sub2_service_data.length>0 ? "form-control btn-group mt-1": "form-control btn-group mt-1 d-none"} name="service2"
                    value={current_sub2_service}
                    onChange={(event) => setcurrent_sub2_service(event.target.value)}>
                <option value="">{gettext("Select sub-service")}</option>
                {sub2_service_data && sub2_service_data.map((item, index) =>
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>)}
            </select>
        )
    }

    return (
        <>
            {ServiceSelect()}
            {Level1ServiceSelect()}
            {Level2ServiceSelect()}
        </>
    )
}

let element = document.getElementById('div_id_parent_service');

if (typeof (element) != 'undefined' && element != null) {
    ReactDOM.render(<SelectServiceLayout/>, document.getElementById('div_id_parent_service'));
}



import Swiper from 'swiper';

var mySwiper = new Swiper('#index_banner', {
    pagination: {
        el: '.swiper-pagination',
    },
})


var swiper = new Swiper('.swiper-container', {
    // Enable lazy loading
    lazy: true,
    observer: true,
    observeParents: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
});

var btns = document.getElementsByClassName("close_btn");

// Loop through the buttons and add the active class to the current/clicked button
for (var i = 0; i < btns.length; i++) {
    btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("custom_photo_preview");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
    });
}

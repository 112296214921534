import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {isMobileOnly} from 'react-device-detect';
import {get_data_fetch} from "./func_fetch";
import {func_getCookie} from "./func_getCookie";
import Moment from "node_modules_path/moment";
import nodata from "../../img/nodata.png";
import loading_image from "../../img/loading.svg";
import {money_format} from "./money_format";
import fileDownload from "js-file-download";

function ContractQuotBarLayout() {
    //country and cities data
    const [data, setData] = useState([]);
    const [data_end, setData_end] = useState([]);
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [rank, setRank] = useState(0);
    const [comment, setComment] = useState("");
    const [editRank, setEditRank] = useState(false);
    const [submitForm, setSubmitForm] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(false);
    const [currentVendorItemId, setCurrentVendorItemId] = useState(false);

    const [itemId, setItemId] = useState(false);
    const [selected_item_id, setSelected_id] = useState(false);
    const [selected_form_id, setSelected_form_id] = useState(false);

    const [current_type, setCurrent_type] = useState();
    const [current_action, setCurrent_action] = useState("");
    const [count, setCount] = useState([]);
    const [previous, setPrevious] = useState([]);
    const [next, setNext] = useState([]);
    let url_link = "http://" + window.location.host;
    let offset = 10;
    const action_type = {"pending": 0, "trading": 1, "finish": 2, "cancel": 3}
    const date = new Date();
    const newDate = addDays(date, 90);
    useEffect(() => {
        (async () => {
            setLoading(true);
            let url = new URL(window.location.href);
            let action = url.searchParams.get("action");
            if (action != null) {
                setCurrent_action(action);
            } else {
                setCurrent_action("pending");
            }
            //api data
            let data_list = await get_data_fetch(
                url_link + '/api/contract_quot_pending_list/',
                "POST",
                {prop_id: current_id, state: action_type[action]}
            );
            let data_end_list = await get_data_fetch(
                url_link + '/api/contract_quot_deadline_list/',
                "POST",
                {prop_id: current_id, state: action_type[action]}
            );
            // if (data_list.count === 0) {
            //     data_list = await get_data_fetch(
            //         url_link + '/api/contract_quot_trading_list/',
            //         "POST",
            //         {prop_id: current_id, state: action_type[action]}
            //     );
            // }
            setCurrent_type(action_type[action]);
            setLoading(false);
            setData(data_list.results);
            setData_end(data_end_list.results);
            // setCount(data_list.count);
            // setPrevious(data_list.previous);
            // setNext(data_list.next);
        })();
    }, []);

    async function get_data(prop, action) {
        setLoading(true);
        let url = new URL(action);
        let next_offset = url.searchParams.get("offset");
        setIndex(next_offset / offset);
        let data_list = await get_data_fetch(
            action,
            "POST",
            {prop_id: current_id, state: prop == null ? action_type[current_action] : prop}
        );
        setLoading(false);
        setData(data_list.results);
    }

    async function accept_quotation(quotation_id, reply_form_id) {
        setLoading(true);
        let data_list = await get_data_fetch(
            url_link + '/api/contract_quot_list_accept',
            "POST",
            {prop_id: current_id, quotation_id: quotation_id, reply_form_id: reply_form_id}
        );
        setLoading(false);
        if (data_list.msg === "done") {
            location.reload();
        }
    }

    async function submit_data() {
        setLoading(true);
        setSubmitForm(true);
        if (editRank) {
            let data_list = await get_data_fetch(url_link + "/api/quot_owner_finish",
                "POST",
                {
                    quot_id: currentItemId,
                    token: token,
                    rank: rank,
                    comment: comment,
                    vendor_id: currentVendorItemId,
                }
            );
            document.querySelector("#owner_property_submit_finish > div > div > div.modal-header > button").click();
            location.href = '/owner/property_detail/' + currentItemId + '?action=finish';
        }
        setLoading(false);
    }

    function addDays(date, days) {
        const copy = new Date(Number(date));
        copy.setDate(date.getDate() + days);
        return copy
    }

    function setRankBtn(val) {
        setEditRank(true);
        setRank(val);
    }

    function contract_end_day(item_date_to) {
        let date_to = new Date(item_date_to);
        let today = new Date();
        let count_days = (date_to.getTime() - today.getTime()) / 1000 / 60 / 60 / 24;

        if (newDate.getDate() >= date_to.getDate()) {
            return <span className="vendor_main_title_color2">({Math.floor(count_days)}days)</span>;
        }
    }

    async function cancel_contract() {
        setLoading(true);

        let data_list = await get_data_fetch(url_link + "/api/contract_cancel",
            "POST",
            {
                itemId: itemId,
            }
        );
        if (data_list.msg === "error") {
            alert("Error");
            setLoading(false);
        }

        if (data_list.msg === "done") {
            setLoading(false);
            location.reload();
        }
    }

    async function renewwal_contract() {
        setLoading(true);

        let data_list = await get_data_fetch(url_link + "/api/contract_renewal",
            "POST",
            {
                renewalItemId: itemId,
            }
        );
        if (data_list.msg === "error") {
            alert("Error");
            setLoading(false);
        }

        if (data_list.msg === "done") {
            setLoading(false);
            location.reload();
        }
    }


    function fileDownloadFROMURL(item) {
        let file_url = window.location.origin + "/download_contract_vendor/" + item.id + "/" + item.file_name;

        return (
            item.file && <a className="btn file_btn" href={file_url} download={item.file_name}>
                {item.file_name}
            </a>
        )
    }

    async function delete_data() {
        setLoading(true);
        let data_list = await get_data_fetch(url_link + "/api/contract_delete",
            "POST",
            {
                quot_id: itemId,
                token: token,
            }
        );
        location.reload();
        setLoading(false);
    }

    function desktopLayout(item) {
        return (
            <>
                <div className="card-body body_bk_color_white contract_layout" key={item.id}>
                    <div className="row">
                        <div className="col-2 title_size">{gettext("Contract")}</div>
                        <div className="col-7">
                            <span>{item.date_from} - {item.date_to}</span>
                            {item.state === 0 &&
                            <span className="vendor_main_title_color2">({gettext("Submitted")})</span>}
                            {item.state === 1 && !item.contract_accept &&
                            <span className="vendor_main_title_color2">({gettext("Waiting approve")})</span>}
                            {item.state === 1 && item.contract_accept &&
                            <span className="vendor_main_title_color2">({gettext("In progress")})</span>}
                            {item.state === 1 && item.contract_accept && contract_end_day(item.date_to)}
                            {item.state === 4 &&
                            <span className="vendor_main_title_color2">({gettext("Cancelled")})</span>}
                        </div>
                        <div className="col-3">
                            <small>{Moment(item.created_at).format('YYYY-MM-DD hh:mm a')}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2 title_size">{gettext("File")}</div>
                        <div className="col-10">
                            {fileDownloadFROMURL(item)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2 title_size">{gettext("Remark")}</div>
                        <div className="col-10">{item.remark}</div>
                    </div>
                    {item.apply_contract_form.length === 0 &&
                    <div className="float-right">
                        <button type="button" className="btn login_btn"
                                data-toggle="modal"
                                data-target="#owner_contract_remove_confirm"
                                onClick={() => {
                                    setItemId(item.id)
                                }}>
                            {gettext("Delete")}
                        </button>
                    </div>
                    }
                </div>
            </>
        )
    }

    function mobileLayout(item) {
        return (
            <div key={item.id}>
                <div className="member_price_bk p-1">
                    <span>
                        {item.state === 0 &&
                        <span className="vendor_main_title_color2">({gettext("Submitted")})</span>}
                        {item.state === 1 && !item.contract_accept &&
                        <span className="vendor_main_title_color2">({gettext("Waiting approve")})</span>}
                        {item.state === 1 && item.contract_accept &&
                        <span className="vendor_main_title_color2">({gettext("In progress")})</span>}
                        {item.state === 1 && item.contract_accept && contract_end_day(item.date_to)}
                    </span>
                    <span className="float-right pr-4">
                        {Moment(item.created_at).format('YYYY-MM-DD hh:mm a')}
                    </span>
                </div>
                <div className="card-body body_bk_color_white">
                    <div className="row">
                        <div className="col title_size">{gettext("Contract")}</div>
                        <div className="col">{item.date_from} - {item.date_to}{contract_end_day(item.date_to)}</div>
                    </div>
                    <div className="row">
                        <div className="col title_size">{gettext("File")}</div>
                        <div className="col">
                            {fileDownloadFROMURL(item)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col title_size">{gettext("Remark")}</div>
                        <div className="col">{item.remark}</div>
                    </div>
                    {item.apply_contract_form.length === 0 &&
                    <div className="float-right">
                        <button type="button" className="btn login_btn"
                                data-toggle="modal"
                                data-target="#owner_contract_remove_confirm"
                                onClick={() => {
                                    setItemId(item.id)
                                }}>
                            {gettext("Delete")}
                        </button>
                    </div>
                    }
                </div>
            </div>
        )
    }

    function contractDetail(contract_id, reply_id) {
        location.href = url_link + '/owner/property_detail/contract/' + reply_id
    }

    function submitFinishLayout() {
        return (
            <div className="modal" id="owner_property_submit_finish" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{gettext("Submit the service")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div>{gettext("Rank")}:<span className="ml-3">{rank}</span>/5</div>
                            <div className="pl-3 pr-3 pb-3">
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(1)}>
                                    <i className={rank >= 1 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(2)}>
                                    <i className={rank >= 2 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(3)}>
                                    <i className={rank >= 3 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(4)}>
                                    <i className={rank >= 4 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(5)}>
                                    <i className={rank >= 5 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                            </div>
                            <div>
                                <textarea className="form-control" placeholder={gettext("comment")} value={comment}
                                          rows="3"
                                          onChange={(event) => setComment(event.target.value)}></textarea>
                            </div>
                            <div className="text-danger font-weight-bold">
                                {!editRank && submitForm && gettext("Please give a rank")}
                            </div>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn login_btn"
                                    onClick={() => {
                                        !loading && submit_data()
                                    }}>{gettext("Submit")}</button>
                            <button type="button" className="btn login_btn"
                                    data-dismiss="modal">{gettext("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function confirmCancelLayout() {
        return (
            <div className="modal" id="owner_contract_cancel_confirm" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{gettext("Confirm")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div>{gettext("Do you want cancel this contract?")}</div>
                            <div>{gettext("We will contact you when you submit the request.")}</div>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn login_btn"
                                    onClick={() => {
                                        cancel_contract()
                                    }}>{gettext("Submit")}</button>
                            <button type="button" className="btn login_btn"
                                    data-dismiss="modal">{gettext("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function confirmRenewalLayout() {
        return (
            <div className="modal" id="owner_contract_renewal_confirm" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{gettext("Confirm")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div>{gettext("Do you want renewal this contract?")}</div>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn login_btn"
                                    onClick={() => {
                                        renewwal_contract()
                                    }}>{gettext("Submit")}</button>
                            <button type="button" className="btn login_btn"
                                    data-dismiss="modal">{gettext("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function confirmRemoveLayout() {
        return (
            <div className="modal" id="owner_contract_remove_confirm" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{gettext("Confirm")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div>{gettext("Do you want delete this contract quotation?")}</div>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn login_btn"
                                    onClick={() => {
                                        delete_data()
                                    }}>{gettext("Confirm")}</button>
                            <button type="button" className="btn login_btn"
                                    data-dismiss="modal">{gettext("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function confirmAcceptPriceLayout() {
        return (
            <div className="modal" id="owner_contract_accept_price_confirm" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{gettext("Confirm")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div>{gettext("Do you accept this price?")}</div>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn login_btn"
                                    onClick={() => {
                                        accept_quotation(selected_item_id, selected_form_id);
                                    }}>{gettext("Confirm")}</button>
                            <button type="button" className="btn login_btn"
                                    data-dismiss="modal">{gettext("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    function renewbutton(item, form_data) {
        if (item.is_show_renew) {
            if (!item.owner_confirm_renewal) {
                return (
                    <button type="button" className="btn login_btn"
                            data-toggle="modal"
                            data-target="#owner_contract_renewal_confirm"
                            onClick={() => {
                                setItemId(form_data.id)
                            }}>
                        {gettext("Renewal")}
                    </button>
                )
            }
        }
        return null
    }


    function cancelBtn(item) {
        if (!item.vendor_cancel && !item.owner_cancel) {
            if (!item.owner_confirm_renewal) {
                return (
                    <button type="button" className="btn login_btn"
                            data-toggle="modal"
                            data-target="#owner_contract_cancel_confirm"
                            onClick={() => {
                                setItemId(item.id)
                            }}>
                        {gettext("Cancel")}
                    </button>
                )
            }
        }
        return null
    }

    return (
        <>
            {submitFinishLayout()}
            {confirmCancelLayout()}
            {confirmRenewalLayout()}
            {confirmRemoveLayout()}
            {confirmAcceptPriceLayout()}
            <div>
                {data.length > 0 && data.map((item) => {
                    return (
                        <section className="card mt-3 body_bk_color_dark_white" key={item.id}>
                            {isMobileOnly ? mobileLayout(item) : desktopLayout(item)}
                            {
                                item.apply_contract_form.length > 0 && item.apply_contract_form.map((form_data) => {
                                    return (
                                        <div key={form_data.id}>
                                            <div className="card-body body_bk_color_white contract_layout mt-1">
                                                <div className="row">
                                                    <div className="col-sm-0 col-md-2">
                                                        <img className="mr-3 align-self-center product_icon"
                                                             src={form_data.vendor.user_profile.logo}
                                                             alt="Generic placeholder image"/>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="title_size">{gettext("Price")}</div>
                                                        <div>{money_format(form_data.price)}</div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="title_size">{gettext("Remark")}</div>
                                                        <div>{form_data.remark}</div>
                                                    </div>
                                                    {
                                                        item.state === 0 &&
                                                        <div className="col-4">
                                                            <div type="button" className="btn send_btn"
                                                                 data-toggle="modal"
                                                                 data-target="#owner_contract_accept_price_confirm"
                                                                 onClick={() => {
                                                                     setSelected_id(item.id);
                                                                     setSelected_form_id(form_data.id);
                                                                 }}>
                                                                {gettext("Accept the price?")}
                                                            </div>
                                                            {/*<div onClick={() => {*/}
                                                            {/*    accept_quotation(item.id, form_data.id)*/}
                                                            {/*}} role="button"*/}
                                                            {/*     className="btn send_btn">{gettext("Accept the price")}*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    }
                                                    {
                                                        item.state === 1 && item.contract_accept &&
                                                        <div className="col-4 text-right">
                                                            <button type="button" className="btn login_btn"
                                                                    onClick={() => {
                                                                        contractDetail(item.id, form_data.id);
                                                                    }}>
                                                                {gettext("Records")}
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-sm-0 col-md-2"></div>
                                                    <div className="col-5">
                                                        <div className="title_size">{gettext("New tenant service fee")}</div>
                                                        <div>{money_format(form_data.new_tenant_service_fee)}</div>
                                                    </div>
                                                    <div className="col-5">
                                                        <div className="title_size">{gettext("Monthly management fee")}</div>
                                                        <div>{money_format(form_data.monthly_management_fee)}</div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-sm-0 col-md-2"></div>
                                                    <div className="col-10">
                                                        <div className="title_size">{gettext("Tenant renewal fee")}</div>
                                                        <div>{money_format(form_data.tenant_renewal_fee)}</div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-sm-0 col-md-2"></div>
                                                    <div className="col-5">
                                                        <div className="title_size">{gettext("Inventory check in inspection")}</div>
                                                        <div>{form_data.inventory_check_in_inspection === "" ? gettext("No data") : form_data.inventory_check_in_inspection}</div>
                                                    </div>
                                                    <div className="col-5">
                                                        <div className="title_size">{gettext("Check out inspection")}</div>
                                                        <div>{form_data.check_out_inspection === "" ? gettext("No data") : form_data.check_out_inspection}</div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-0 col-md-2"></div>
                                                    <div className="col-5">
                                                        <div className="title_size">{gettext("Non resident")}</div>
                                                        <div>{form_data.non_resident ? gettext("Yes") : gettext("No")}</div>
                                                    </div>
                                                    <div className="col-5">
                                                        <div className="title_size">{gettext("Require tax return")}</div>
                                                        <div>{form_data.require_tax_return ? gettext("Yes") : gettext("No")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {item.state === 1 && item.contract_accept &&
                                            <div className="card-body body_bk_color_white contract_layout">
                                                <div className="row">
                                                    <div className="col-12 text-right">
                                                        {item.vendor_cancel && !item.owner_cancel && <span
                                                            className="text-danger">{gettext("Vendor is applying canceling contract")}</span>}
                                                        {item.owner_cancel && !item.vendor_cancel && <span
                                                            className="text-danger">{gettext("Owner is applying canceling contract")}</span>}
                                                        {!item.owner_confirm_renewal && item.vendor_confirm_renewal &&
                                                        <span
                                                            className="text-danger">{gettext("Vendor is applying renew contract")}</span>}
                                                        {item.owner_confirm_renewal && !item.vendor_confirm_renewal &&
                                                        <span
                                                            className="text-danger">{gettext("Waiting vendor approve renew contract")}</span>}
                                                        {item.owner_confirm_renewal && item.vendor_confirm_renewal && !item.contract_renewed &&
                                                        <span
                                                            className="text-danger">{gettext("Waiting HoHoLink approve the new contract")}</span>}
                                                        <div>
                                                            {cancelBtn(item, form_data)}
                                                            {renewbutton(item, form_data)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </section>
                    )
                })
                }
                {
                    data.length === 0 && data_end.length === 0 && <div className="text-center p-3"><img src={nodata} height="50px"/>
                        <div>No data</div>
                    </div>
                }
                {loading && <div className="text-center"><img src={loading_image}/></div>}
            </div>
        </>
    )
}

let element = document.getElementById('owner_contract_quot_list_single_bar');
if (typeof (element) != 'undefined' && element != null) {
    ReactDOM.render(<ContractQuotBarLayout/>, document.getElementById('owner_contract_quot_list_single_bar'));
}



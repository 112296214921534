import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {isMobileOnly} from 'react-device-detect';
import {get_data_fetch} from "./func_fetch";
import {func_getCookie} from "./func_getCookie";
import Moment from "node_modules_path/moment";
import nodata from "../../img/nodata.png";
import loading_image from "../../img/loading.svg";
import fileDownload from "js-file-download";
import {money_format} from "./money_format";

function QuotBarLayout() {
    //country and cities data
    const [data, setData] = useState([]);
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [rank, setRank] = useState(0);
    const [comment, setComment] = useState("");
    const [editRank, setEditRank] = useState(false);
    const [submitForm, setSubmitForm] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(false);
    const [currentProperty, setCurrentProperty] = useState("");
    const [currentVendorItemId, setCurrentVendorItemId] = useState(false);
    const [current_type, setCurrent_type] = useState();
    const [current_action, setCurrent_action] = useState("");
    const [count, setCount] = useState([]);
    const [previous, setPrevious] = useState([]);
    const [next, setNext] = useState([]);
    const [itemId, setItemId] = useState(false);
    const [selectedVendorId, setVendorId] = useState(0);
    const [selectedQuotId, setQuotId] = useState(0);


    let url_link = "http://" + window.location.host;
    let offset = 10;
    const action_type = {"pending": 0, "trading": 1, "finish": 2, "cancel": 3}

    useEffect(() => {
        (async () => {
            setLoading(true);
            let url = new URL(window.location.href);
            let action = url.searchParams.get("action");
            if (action != null) {
                setCurrent_action(action);
            } else {
                setCurrent_action("pending");
            }
            //api data
            let data_list = await get_data_fetch(
                url_link + '/api/quot_list/',
                "POST",
                {prop_id: current_id, state: action_type[action]}
            );
            setCurrent_type(action_type[action]);
            setLoading(false);
            setData(data_list.results);
            setCount(data_list.count);
            setPrevious(data_list.previous);
            setNext(data_list.next);
        })();
    }, []);


    async function get_data(prop, action) {
        setLoading(true);
        let url = new URL(action);
        let next_offset = url.searchParams.get("offset");
        setIndex(next_offset / offset);
        let data_list = await get_data_fetch(
            action,
            "POST",
            {prop_id: current_id, state: prop == null ? action_type[current_action] : prop}
        );
        setLoading(false);
        setData(data_list.results);
    }


    async function accept_quotation(quotation_id, reply_form_id) {
        setLoading(true);
        let data_list = await get_data_fetch(
            url_link + '/api/quot_list_accept',
            "POST",
            {prop_id: current_id, quotation_id: quotation_id, reply_form_id: reply_form_id}
        );
        setLoading(false);
        if (data_list.msg === "done") {
            location.reload();
        }
    }

    function pagination() {
        let cur_index = 0;
        let table = [];
        for (let i = 0; i < count; i = i + offset) {
            table.push(
                <li key={i} className={cur_index === index ? "page-item ml-1 mr-1 active" : "page-item ml-1 mr-1"}>
                    <div className="page-link" onClick={() => {
                        get_data(null, url_link + '/api/quot_list/?offset=' + i)
                    }}>{cur_index + 1}</div>
                </li>)
            cur_index++;
        }
        return table
    }

    async function submit_data() {
        setLoading(true);
        if (editRank) {

            let data_list = await get_data_fetch(url_link + "/api/quot_owner_finish",
                "POST",
                {
                    quot_id: currentItemId,
                    token: token,
                    rank: rank,
                    comment:comment,
                    vendor_id: currentVendorItemId,
                }
            );
            document.querySelector("#owner_property_submit_finish2 > div > div > div.modal-header > button").click();
            location.href = '/owner/property_detail/' + currentProperty + '?action=finish';
        }
        setLoading(false);
    }


    function setRankBtn(val) {
        setEditRank(true);
        setRank(val);
    }

    function fileDownloadFROMURL(device_type, item) {
        let file_url = window.location.origin + "/download_service/" + item.id + "/" + item.file_name;

        return (
            device_type === "desktop" ?
                <div className="row mt-3">
                    <div className="col">
                        <div className="title_size">{gettext("File")}</div>
                        <div>
                            <a className="btn file_btn" href={file_url} download={item.file_name}>
                                {item.file_name}
                            </a>
                        </div>
                    </div>
                </div> :
                <a className="btn file_btn"  href={file_url} download={item.file_name}>
                    {item.file_name}
                </a>
        )
    }

    async function delete_data() {
        setLoading(true);
        let data_list = await get_data_fetch(url_link + "/api/quot_owner_delete",
            "POST",
            {
                quot_id: itemId,
                token: token,
            }
        );
        location.reload();
        setLoading(false);
    }

    function confirmRemoveLayout() {
        return (
            <div className="modal" id="owner_quot_remove_confirm" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{gettext("Confirm")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div>{gettext("Do you want delete this contract quotation?")}</div>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn login_btn"
                                    onClick={() => {
                                        delete_data()
                                    }}>{gettext("Confirm")}</button>
                            <button type="button" className="btn login_btn"
                                    data-dismiss="modal">{gettext("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    function desktopLayout(item) {
        return (
            <>
                <div className="member_price_bk p-1">
                    <span className="float-right pr-4">
                        {Moment(item.created_at).format('YYYY-MM-DD hh:mm a')}
                    </span>
                </div>
                <div className="card-body body_bk_color_white">
                    <div className="row">
                        <div className="col">
                            <div className="title_size">{gettext("Property")}</div>
                            <div>{item.property && item.property.name}</div>
                        </div>
                        <div className="col">
                            <div className="title_size">{gettext("Location")}</div>
                            <div>{item.property && `${item.property.country.name} / ${item.property && item.property.city.name}`}</div>
                        </div>
                        <div className="col">
                            <div className="title_size">{gettext("Select service")}</div>
                            <div>{item.parent_service.name}{item.service && "/ " + item.service.name}{item.service2 && "/ " + item.service2.name}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <div className="title_size">{gettext("Start date")}</div>
                            <div>{item.date}</div>
                        </div>
                        <div className="col">
                            <div className="title_size">{gettext("Length of service")}</div>
                            <div>{item.hours} {gettext("Hours")}</div>
                        </div>
                        <div className="col"></div>
                    </div>
                    {item.file && fileDownloadFROMURL('desktop', item)}
                    <div className="row mt-3">
                        <div className="col">
                            <div className="title_size">{gettext("Remark")}</div>
                            <div>{item.remark}</div>
                        </div>
                    </div>
                    {item.apply_forms.length === 0 &&
                    <div className="float-right">
                        <button type="button" className="btn login_btn"
                                data-toggle="modal"
                                data-target="#owner_quot_remove_confirm"
                                onClick={() => {
                                    setItemId(item.id)
                                }}>
                            {gettext("Delete")}
                        </button>
                    </div>
                    }
                </div>
            </>
        )
    }

    function mobileLayout(item) {
        return (
            <>
                <div className="member_price_bk p-1">
                    {/*<span className="float-left pl-4">*/}
                    {/*    {item.contract ? gettext("Contracts") : gettext("Services")}*/}
                    {/*</span>*/}
                    <span className="float-right pr-4">
                        {Moment(item.created_at).format('YYYY-MM-DD hh:mm a')}
                    </span>
                </div>
                <div className="card-body body_bk_color_white">
                    <div className="row">
                        <div className="col title_size">{gettext("Property")}</div>
                        <div className="col">{item.property && item.property.name}</div>
                    </div>
                    <div className="row">
                        <div className="col title_size">{gettext("Location")}</div>
                        <div
                            className="col">{item.property && `${item.property.country.name} / ${item.property.city.name}`}</div>
                    </div>
                    <div className="row">
                        <div className="col">{gettext("Select service")}</div>
                        <div className="col">{item.parent_service.name}{item.service && "/ "  + item.service.name}{item.service2 && "/ " + item.service2.name}</div>
                    </div>
                    <div className="row">
                        <div className="col">{gettext("Start date")}</div>
                        <div className="col">{item.date}</div>
                    </div>
                    <div className="row">
                        <div className="col">{gettext("File")}</div>
                        <div className="col">{item.file && fileDownloadFROMURL("mobile", item)}</div>
                    </div>

                    {/*<div className="row">*/}
                    {/*    <div className="col">{gettext("Start time")}</div>*/}
                    {/*    <div className="col">{item.date_time}:00</div>*/}
                    {/*</div>*/}
                    <div className="row">
                        <div className="col">{gettext("Length of service")}</div>
                        <div className="col">{item.hours} {gettext("Hours")}</div>
                    </div>
                    <div className="row">
                        <div className="col">{gettext("Remark")}</div>
                        <div className="col">{item.remark}</div>
                    </div>
                    {item.apply_forms.length === 0 &&
                    <div className="row">
                        <div className="col">
                            <button type="button" className="btn login_btn"
                                    data-toggle="modal"
                                    data-target="#owner_quot_remove_confirm"
                                    onClick={() => {
                                        setItemId(item.id)
                                    }}>
                                {gettext("Delete")}
                            </button>
                        </div>
                    </div>
                    }
                </div>
            </>
        )
    }

    function submit_finish_layout() {
        return (
            <div className="modal" id="owner_property_submit_finish2" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{gettext("Submit the service")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div>{gettext("Rank")}:<span className="ml-3">{rank}</span>/5</div>
                            <div className="pl-3 pr-3 pb-3">
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(1)}>
                                    <i className={rank >= 1 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(2)}>
                                    <i className={rank >= 2 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(3)}>
                                    <i className={rank >= 3 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(4)}>
                                    <i className={rank >= 4 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(5)}>
                                    <i className={rank >= 5 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                            </div>
                            <div>
                                <textarea className="form-control" placeholder={gettext("comment")} value={comment}
                                          rows="3"
                                          onChange={(event) => setComment(event.target.value)}></textarea>
                            </div>
                            <div className="text-danger font-weight-bold">
                                {!editRank && submitForm && gettext("Please give a rank and comment")}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn login_btn"
                                    onClick={() => {
                                        !loading && submit_data()
                                    }}>{gettext("Confirm")}</button>
                            <button type="button" className="btn login_btn"
                                    data-dismiss="modal">{gettext("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    function confirmAcceptPriceLayout() {
        return (
            <div className="modal" id="owner_accept_price_confirm" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{gettext("Confirm")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div>{gettext("Do you accept this price?")}</div>
                            {loading && <div className="text-center"><img src={loading_image}/></div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn login_btn"
                                    onClick={() => {
                                        accept_quotation(selectedVendorId, selectedQuotId);
                                    }}>{gettext("Confirm")}</button>
                            <button type="button" className="btn login_btn"
                                    data-dismiss="modal">{gettext("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {confirmAcceptPriceLayout()}
            {submit_finish_layout()}
            {confirmRemoveLayout()}
            <div
                className="row text-center border border-warning price_title btn-group btn-group-toggle owner_bar_border"
                data-toggle="buttons">
                <label className={current_action === "pending" ? "col-4 btn ml-0 active" : "col-4 btn ml-0"}
                       onClick={() => {
                           location.href = '/owner/property_detail/' + current_id + '?action=pending';
                       }}>
                    <input type="radio"
                           name="options"
                           id="option1"
                           autoComplete="off"
                           defaultChecked={current_action === "pending" ? "defaultChecked" : ""}/>
                           {gettext("Submitted")}
                </label>

                <label className={current_action === "trading" ? "col-4 btn ml-0 active" : "col-4 btn ml-0"}
                       onClick={() => {
                           location.href = '/owner/property_detail/' + current_id + '?action=trading';
                       }}>
                    <input type="radio"
                           name="options"
                           id="option2"
                           autoComplete="off"
                           defaultChecked={current_action === "trading" ? "defaultChecked" : ""}/>
                           {gettext("In progress")}
                </label>

                <label className={current_action === "finish" ? "col-4 btn ml-0 active" : "col-4 btn ml-0"}
                       onClick={() => {
                           location.href = '/owner/property_detail/' + current_id + '?action=finish';
                       }}>
                    <input type="radio"
                           name="options"
                           id="option3"
                           autoComplete="off"
                           defaultChecked={current_action === "finish" ? "defaultChecked" : ""}/>
                           {gettext("Completed")}
                </label>

                {/*<label className={current_action == "cancel" ? "col-3 btn ml-0 active" : "col-3 btn ml-0"}*/}
                {/*       onClick={() => {*/}
                {/*           location.href = '/owner/property_detail/' + current_id + '?action=cancel';*/}
                {/*       }}>*/}
                {/*    <input type="radio" name="options" id="option4" autoComplete="off"*/}
                {/*           defaultChecked/>{gettext("Canceled")}*/}
                {/*</label>*/}
            </div>
            <div>
                {data.length > 0 && data.map((item) => {
                    return (
                        <section className="card mt-3 body_bk_color_dark_white" key={item.id}>
                            {isMobileOnly ? mobileLayout(item) : desktopLayout(item)}
                            {
                                item.apply_forms.length > 0 && item.apply_forms.map((form_data) => {
                                    return (
                                        <li className="media border-bottom mb-3 mt-3 pl-2 pr-2" key={form_data.id}>
                                            <img className="mr-3 align-self-center product_icon"
                                                 src={form_data.vendor.user_profile.logo}/>
                                            <div className="media-body">
                                                <a className="vendor_href" href={'/agency/'+ form_data.vendor.user_profile.id + '/'+ form_data.vendor.user_profile.name}>
                                                    <h6>
                                                        {form_data.vendor.user_profile.name}
                                                        ({form_data.vendor.user_profile.id})
                                                    </h6>
                                                </a>
                                                <div className="row">
                                                    <div className="col">
                                                        {/*<p className="product_type_text">家居清潔 / 中國香港</p>*/}
                                                    </div>
                                                    {form_data.state === 0 && <div className="col">
                                                        <div onClick={() => {
                                                            setVendorId(item.id);
                                                            setQuotId(form_data.id);
                                                        }}
                                                             className="btn send_btn"
                                                            data-toggle="modal" data-target="#owner_accept_price_confirm"
                                                        >{gettext("Accept the price?")}</div>
                                                    </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        {gettext("Remark")}: {form_data.remark}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div
                                                            className="d-flex justify-content-between align-items-center">
                                                            <div>
                                                                    <span>
                                                                        <span
                                                                            className={form_data.rank_avg >= 1 ? "star fas fa-star checked" : "star fas fa-star"}/>
                                                                        <span
                                                                            className={form_data.rank_avg >= 2 ? "star fas fa-star checked" : "star fas fa-star"}/>
                                                                        <span
                                                                            className={form_data.rank_avg >= 3 ? "star fas fa-star checked" : "star fas fa-star"}/>
                                                                        <span
                                                                            className={form_data.rank_avg >= 4 ? "star fas fa-star checked" : "star fas fa-star"}/>
                                                                        <span
                                                                            className={form_data.rank_avg >= 5 ? "star fas fa-star checked" : "star fas fa-star"}/>
                                                                    </span>
                                                                <span>(<span>33</span>)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col">{gettext("Remark")}: {money_format(form_data.price)}</div>
                                                    <div className="col">{
                                                        item.vendor_confirm_finish && !item.owner_confirm_finish &&
                                                        <button type="button" className="btn login_btn"
                                                                data-toggle="modal"
                                                                data-target="#owner_property_submit_finish2"
                                                                onClick={() => {
                                                                    setCurrentProperty(item.property.pk);
                                                                    setCurrentItemId(item.id);
                                                                    setCurrentVendorItemId(form_data.id);
                                                                }}
                                                        >
                                                            {gettext("Finish")}
                                                        </button>
                                                    }</div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </section>
                    )
                })
                }
                {
                    data.length == 0 && <div className="text-center p-3"><img src={nodata} height="50px"/>
                        <div>No data</div>
                    </div>
                }
                {loading && <div className="text-center"><img src={loading_image}/></div>}
            </div>
            <ul className="pagination justify-content-center mt-3">
                <li className="page-item">
                    <div className="page-link" aria-label="Previous" onClick={() => {
                        previous != null && get_data(null, previous)
                    }}>
                        <span aria-hidden="true">&laquo;</span>
                    </div>
                </li>
                {pagination()}
                <li className="page-item">
                    <div className="page-link" aria-label="Next" onClick={() => {
                        next != null && get_data(null, next)
                    }}>
                        <span aria-hidden="true">&raquo;</span>
                    </div>
                </li>
            </ul>
        </>
    )
}

let element = document.getElementById('owner_quot_list_single_bar');
if (typeof (element) != 'undefined' && element != null) {
    ReactDOM.render(<QuotBarLayout/>, document.getElementById('owner_quot_list_single_bar'));
}



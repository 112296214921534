import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";

function SortButtonLayout() {

    //country data
    const [country, setcountry] = useState(0);

    //cities data
    const [city, setcity] = useState(0);

    //service data
    const [service, setservice] = useState(0);

    const [sort_country, setSort_country] = useState(0);
    const [sort_ranking, setSort_ranking] = useState(0);

    const [formRef, setFormRef] = useState(null);

    const formRefSet = element => {
        setFormRef(element)
    }

    const [changeValue, setChangeValue] = useState(false);

    let url_link = "http://" + window.location.host;

    useEffect(() => {
        (async () => {
            let url = new URL(window.location.href);
            let url_country = url.searchParams.get("country");
            let url_district = url.searchParams.get("district");
            let url_service_type = url.searchParams.get("service_type");

            let sort_country = url.searchParams.get("sort_country");
            let sort_ranking = url.searchParams.get("sort_ranking");
            setcountry(url_country);
            setcity(url_district);
            setservice(url_service_type);
            setSort_country(sort_country==null?0:sort_country);
            setSort_ranking(sort_ranking==null?0:sort_ranking);

        })();
    }, [formRef]);


    function sort_country_func() {
        setSort_ranking(0);
        switch (parseInt(sort_country)) {
            case 0:
                setSort_country(1);
                break;
            case 1:
                setSort_country(2);
                break;
            case 2:
                setSort_country(0);
                break;
        }
        setChangeValue(true);
    }

    function sort_ranking_func() {
        setSort_country(0)
        switch (parseInt(sort_ranking)) {
            case 0:
                setSort_ranking(1);
                break;
            case 1:
                setSort_ranking(2);
                break;
            case 2:
                setSort_ranking(0);
                break;
        }
        setChangeValue(true);
    }

    function submit_form() {
        formRef.submit();
    }


    return (
        <>
            <form action={url_link + "/service"} ref={formRefSet} hidden={true}>
                <input name="country" value={country} readOnly={true}/>
                <input name="district" value={city} readOnly={true}/>
                <input name="service_type" value={service} readOnly={true}/>
                <input name="sort_country" value={sort_country} readOnly={true}/>
                <input name="sort_ranking" value={sort_ranking} readOnly={true}/>
                {changeValue && submit_form()}
            </form>
            <div className="d-inline-flex justify-content-center pt-2 unselection search_btn_sort" onClick={() => {
                sort_country_func()
            }}>
                <h6>{gettext("Country")}</h6>
                <div className="pl-1">
                    {parseInt(sort_country) === 1 && <i className="fas fa-arrow-up"/>}
                    {parseInt(sort_country) === 2 && <i className="fas fa-arrow-down"/>}
                </div>
            </div>
            <div className="d-inline-flex justify-content-center pt-2 unselection search_btn_sort" onClick={() => {
                sort_ranking_func()
            }}>
                <h6>{gettext("Ranking")}</h6>
                <div className="pl-1">
                    {parseInt(sort_ranking) === 1 && <i className="fas fa-arrow-up"/>}
                    {parseInt(sort_ranking) === 2 && <i className="fas fa-arrow-down"/>}
                </div>
            </div>
        </>
    )
}

let formElement = document.getElementById('sort_bar');
if (typeof (formElement) != 'undefined' && formElement != null) {
    ReactDOM.render(<SortButtonLayout/>, document.getElementById('sort_bar'));
}




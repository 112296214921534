import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {isMobileOnly} from 'react-device-detect';
import {get_data_fetch} from "./func_fetch";
import {func_getCookie} from "./func_getCookie";
import Moment from "node_modules_path/moment";
import nodata from "../../img/nodata.png";
import loading_image from "../../img/loading.svg";
import {money_format} from "./money_format";
import fileDownload from "js-file-download";

function VendorContractListBarLayout() {
    //country and cities data
    const [data, setData] = useState([]);
    const [data_end, setData_end] = useState([]);
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [rank, setRank] = useState(0);
    const [comment, setComment] = useState("");
    const [editRank, setEditRank] = useState(false);
    const [submitForm, setSubmitForm] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(false);
    const [currentVendorItemId, setCurrentVendorItemId] = useState(false);
    const [itemId, setItemId] = useState(false);
    const [current_type, setCurrent_type] = useState();
    const [current_action, setCurrent_action] = useState("");
    const [count, setCount] = useState([]);
    const [previous, setPrevious] = useState([]);
    const [next, setNext] = useState([]);
    let url_link = "http://" + window.location.host;
    let offset = 10;
    const action_type = {"pending": 0, "trading": 1, "finish": 2, "cancel": 3};
    const date = new Date();
    const newDate = addDays(date, 90);


    useEffect(() => {
        (async () => {
            setLoading(true);
            let url = new URL(window.location.href);
            let action = url.searchParams.get("action");
            if (action != null) {
                setCurrent_action(action);
            } else {
                setCurrent_action("pending");
            }

            //api data
            let data_list = await get_data_fetch(
                url_link + '/api/vendor_contract_deadline/',
                "POST",
                apply_form_id != null ? {apply_form_id: parseInt(apply_form_id)} : {}
            );

            // let data_end_list = await get_data_fetch(
            //     url_link + '/api/vendor_contract_deadline/',
            //     "POST",
            //     {prop_id: current_id, state: action_type[action]}
            // );

            setCurrent_type(action_type[action]);
            setLoading(false);
            setData(data_list.results);
            // setData_end(data_end_list.results);
            // setCount(data_list.count);
            // setPrevious(data_list.previous);
            // setNext(data_list.next);
        })();
    }, []);

    function addDays(date, days) {
        const copy = new Date(Number(date));
        copy.setDate(date.getDate() + days);
        return copy
    }

    function fileDownloadFROMURL(item) {
        let file_url = window.location.origin + "/download_contract/" + item.id + "/" + item.file_name;

        return (
            item.file && <a className="btn file_btn" href={file_url} download={item.file_name}>
                {item.file_name}
            </a>
        )
    }

    function desktopDeadLineLayout(item) {
        return (
            <>
                <div className="card-body body_bk_color_white contract_layout" key={"a" + item.owner_quot_id.id}>
                    <div className="row">
                        <div className="col-2">
                            <span>{gettext("Contract")}</span>
                            <span>
                                <span className="vendor_main_title_color3">({gettext("Contract had expired")})</span>
                            </span>
                        </div>
                        <div className="col-5">{item.owner_quot_id.property.name}</div>
                        <div
                            className="col-5 text-right">({item.owner_quot_id.date_from} - {item.owner_quot_id.date_to})
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">{gettext("File")}</div>
                        <div className="col-10">
                            {fileDownloadFROMURL(item.owner_quot_id)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">{gettext("Remark")}</div>
                        <div className="col-10">{item.owner_quot_id.remark}</div>
                    </div>
                </div>
                <div className="card-body body_bk_color_white contract_layout mt-1" key={item.id}>
                    <div className="row">
                        <div className="col-2">{gettext("Price")}</div>
                        <div className="col-6">
                            <small>{money_format(item.price)}</small>
                        </div>
                        <div className="col-4 text-right">
                            <small>{Moment(item.created_at).format('YYYY-MM-DD hh:mm a')}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">{gettext("Remark")}</div>
                        <div className="col-5">{item.remark}</div>
                        <div className="col-5 text-right">
                            {item.state === 1 && item.owner_quot_id.contract_accept &&
                            <a className="btn owner_btn"
                               href={"/vendor/contract/detail/" + item.id}>{gettext("Maintenance Records")}</a>}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function mobileDeadLineLayout(item) {
    return (
        <>
            <div key={"a" + item.owner_quot_id.id}>
                <div className="member_price_bk p-1">
                    <span>{item.owner_quot_id.property.name}</span>
                </div>
                <div className="card-body body_bk_color_white">
                    <div className="row">
                        <div className="col title_size">
                            <span>{gettext("Contract")}</span>
                            <span className="vendor_main_title_color3">({gettext("Contract has expired")})</span>
                        </div>
                        <div className="col">{item.owner_quot_id.date_from} - {item.owner_quot_id.date_to}</div>
                    </div>

                    <div className="row">
                        <div className="col title_size">{gettext("File")}</div>
                        <div className="col">
                            {fileDownloadFROMURL(item.owner_quot_id)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col title_size">{gettext("Remark")}</div>
                        <div className="col">{item.owner_quot_id.remark}</div>
                    </div>
                </div>
            </div>
            <div className="mt-1" key={item.id}>
                <div className="card-body body_bk_color_white">
                    <div className="row">
                        <div className="col title_size">{gettext("Price")}</div>
                        <div className="col">{money_format(item.price)}</div>
                    </div>
                    <div className="row">
                        <div className="col title_size">{gettext("Remark")}</div>
                        <div className="col">{item.remark}</div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {item.state === 1 && item.owner_quot_id.contract_accept &&
                            <a className="btn owner_btn shadow"
                               href={"/vendor/contract/detail/" + item.id}>{gettext("Maintenance Records")}</a>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

    return (
        <>
            <div>
                {data.length > 0 && data.map((item) => {
                        return (
                            <section className="card mt-3 body_bk_color_dark_white" key={item.id}>
                                {isMobileOnly ? mobileDeadLineLayout(item) : desktopDeadLineLayout(item)}
                            </section>
                        )
                    })
                }
                {
                    data.length === 0 && <div className="text-center p-3"><img src={nodata} height="50px"/>
                        <div>No data</div>
                    </div>
                }
                {loading && <div className="text-center"><img src={loading_image}/></div>}
            </div>
        </>
    )
}

let element = document.getElementById('vendor_contract_list');
if (typeof (element) != 'undefined' && element != null) {
    ReactDOM.render(<VendorContractListBarLayout/>, document.getElementById('vendor_contract_list'));
}
import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {get_data_fetch} from "./func_fetch";


function PaymentButtonLayout() {
    //country and cities data
    const [data, setData] = useState([]);

    //country data
    const [loading, setLoading] = useState(false);

    let url_link = "http://" + window.location.host;

    async function payment_tran_paypal() {
        setLoading(true);
        let url = new URL(window.location.href);
        // let user_id = {{ user.id }};
        // let product_name = "{{ priority.name }}";
        // let product_id = {{ priority.id }};
        // let product_price = {{ priority.price }};
        // let product_level = {{ priority.level }};
        // let product_days= {{ priority.days }};


        let param = {
            user: user_id,
            product: product_id,
            name: product_name,
            token: product_price,
            days: product_days,
            product_type: "priority",
        }

        //create_order
        let data_order = await get_data_fetch(url_link + '/api/create_order/', "POST", param);
        if(data_order.msg==="done"){
            location.href = url_link + '/vendor/done/';
        }else{
            location.href = url_link + '/vendor/canceled/';
        }
    }


    return (
        <div className="btn login_btn text-white">
            {!loading && <span onClick={payment_tran_paypal}>確定購買</span>}
            {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>}
            {loading && <span>Loading...</span>}
        </div>
    )
}

let element = document.getElementById('priority_payment_button');
if (typeof (element) != 'undefined' && element != null) {
    ReactDOM.render(<PaymentButtonLayout/>, document.getElementById('priority_payment_button'));
}




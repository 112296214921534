//
// function initMap(x,y){
//     createMap(x,y);
//     setMapEvent();
//     addMapControl();
// }
//
//
// function createMap(x,y){
//     if(typeof x != "undefined"){
//         var map = new BMap.Map("dituContent");
//         var point = new BMap.Point(map_x,map_y);
//         map.centerAndZoom(point,17);
//         window.map = map;
//     }
// }
//
//
// function setMapEvent(){
//     map.enableDragging();
//     map.disableScrollWheelZoom();
//     map.enableDoubleClickZoom();
//     map.disableKeyboard();
// }
//
// function addMapControl(){
// }
//
//
// if(typeof map_x != "undefined"){
//     initMap(map_x,map_y);
// }

let map;

async function initMap() {
    const {Map} = await google.maps.importLibrary("maps");
    map = new Map(document.getElementById("dituContent"), {
        center: {lat: map_x, lng: map_y},
        zoom: 8,
    });
}

if (typeof map_x != "undefined") {
    initMap();
}

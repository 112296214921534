import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {isMobileOnly} from 'react-device-detect';
import {get_data_fetch} from "./func_fetch";
import {func_getCookie} from "./func_getCookie";
import Moment from "node_modules_path/moment";
import nodata from "../../img/nodata.png";
import loading_image from "../../img/loading.svg";
import {money_format} from "./money_format";
import fileDownload from "js-file-download";

function VendorContractBarLayout() {
    //country and cities data
    const [data, setData] = useState([]);
    const [data_end, setData_end] = useState([]);
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [rank, setRank] = useState(0);
    const [comment, setComment] = useState("");
    const [editRank, setEditRank] = useState(false);
    const [submitForm, setSubmitForm] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(false);
    const [currentVendorItemId, setCurrentVendorItemId] = useState(false);
    const [itemId, setItemId] = useState(false);
    const [current_type, setCurrent_type] = useState();
    const [current_action, setCurrent_action] = useState("");
    const [count, setCount] = useState([]);
    const [previous, setPrevious] = useState([]);
    const [next, setNext] = useState([]);
    let url_link = "http://" + window.location.host;
    let offset = 10;
    const action_type = {"pending": 0, "trading": 1, "finish": 2, "cancel": 3};
    const date = new Date();
    const newDate = addDays(date, 90);


    useEffect(() => {
        (async () => {
            setLoading(true);
            let url = new URL(window.location.href);
            let action = url.searchParams.get("action");
            if (action != null) {
                setCurrent_action(action);
            } else {
                setCurrent_action("pending");
            }

            //api data
            let data_list = await get_data_fetch(
                url_link + '/api/vendor_contract_pending/',
                "POST",
                apply_form_id != null ? {apply_form_id: parseInt(apply_form_id)} : {}
            );

            // let data_end_list = await get_data_fetch(
            //     url_link + '/api/vendor_contract_deadline/',
            //     "POST",
            //     {prop_id: current_id, state: action_type[action]}
            // );

            setCurrent_type(action_type[action]);
            setLoading(false);
            setData(data_list.results);
            // setData_end(data_end_list.results);
            // setCount(data_list.count);
            // setPrevious(data_list.previous);
            // setNext(data_list.next);
        })();
    }, []);

    async function get_data(prop, action) {
        setLoading(true);
        let url = new URL(action);
        let next_offset = url.searchParams.get("offset");
        setIndex(next_offset / offset);
        let data_list = await get_data_fetch(
            action,
            "POST",
            {prop_id: current_id, state: prop == null ? action_type[current_action] : prop}
        );
        setLoading(false);
        setData(data_list.results);
    }

    async function accept_quotation(quotation_id, reply_form_id) {
        setLoading(true);
        let data_list = await get_data_fetch(
            url_link + '/api/contract_quot_list_accept',
            "POST",
            {prop_id: current_id, quotation_id: quotation_id, reply_form_id: reply_form_id}
        );
        setLoading(false);
        if (data_list.msg === "done") {
            location.reload();
        }
    }

    async function submit_data() {
        setLoading(true);
        setSubmitForm(true);
        if (editRank) {
            let data_list = await get_data_fetch(url_link + "/api/quot_owner_finish",
                "POST",
                {
                    quot_id: currentItemId,
                    token: token,
                    rank: rank,
                    comment: comment,
                    vendor_id: currentVendorItemId,
                }
            );
            document.querySelector("#vendor_property_submit_finish > div > div > div.modal-header > button").click();
            location.href = '/owner/property_detail/' + currentItemId + '?action=finish';
        }
        setLoading(false);
    }

    function addDays(date, days) {
        const copy = new Date(Number(date));
        copy.setDate(date.getDate() + days);
        return copy
    }

    function setRankBtn(val) {
        setEditRank(true);
        setRank(val);
    }

    function contract_end_day(item_date_to) {
        let date_to = new Date(item_date_to);
        let today = new Date();
        let count_days = (date_to.getTime() - today.getTime()) / 1000 / 60 / 60 / 24;

        if (newDate.getDate() >= date_to.getDate()) {
            return <span className="vendor_main_title_color2">({Math.floor(count_days)}days)</span>;
        }
    }

    async function renewwal_contract() {
        setLoading(true);

        let data_list = await get_data_fetch(url_link + "/api/contract_renewal",
            "POST",
            {
                renewalItemId: itemId,
            }
        );
        if (data_list.msg === "error") {
            alert("Error");
            setLoading(false);
        }

        if (data_list.msg === "done") {
            setLoading(false);
            location.reload();
        }
    }

    async function cancel_contract() {
        setLoading(true);

        let data_list = await get_data_fetch(url_link + "/api/contract_cancel",
            "POST",
            {
                cancelItemId: itemId,
            }
        );
        if (data_list.msg === "error") {
            alert("Error");
            setLoading(false);
        }

        if (data_list.msg === "done") {
            setLoading(false);
            location.reload();
        }
    }

    function confirmCancelLayout() {
        return (
            <div className="modal" id="vendor_contract_cancel_confirm" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{gettext("Confirm")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div>{gettext("Do you want cancel this contract?")}</div>
                            <div>{gettext("We will contact you when you submit the request.")}</div>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn login_btn"
                                    onClick={() => {
                                        cancel_contract()
                                    }}>{gettext("Submit")}</button>
                            <button type="button" className="btn login_btn"
                                    data-dismiss="modal">{gettext("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function confirmRenewalLayout() {
        return (
            <div className="modal" id="vendor_contract_renewal_confirm" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{gettext("Confirm")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div>{gettext("Do you want renewal this contract?")}</div>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn login_btn"
                                    onClick={() => {
                                        renewwal_contract()
                                    }}>{gettext("Submit")}</button>
                            <button type="button" className="btn login_btn"
                                    data-dismiss="modal">{gettext("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function fileDownloadFROMURL(id, item) {
        let file_url = window.location.origin + "/download_contract/" + id + "/" + item.file_name;

        return (
            item.file && <a className="btn file_btn" href={file_url} download={item.file_name}>
                {item.file_name}
            </a>
        )
    }

    function cancelBtn(item) {
        if (!item.owner_quot_id.vendor_cancel && !item.owner_quot_id.owner_cancel) {
            if (!item.owner_quot_id.vendor_confirm_renewal) {
                return (
                    <button type="button" className="btn owner_btn"
                            data-toggle="modal"
                            data-target="#vendor_contract_cancel_confirm"
                            onClick={() => {
                                setItemId(item.id)
                            }}>
                        {gettext("Cancel")}
                    </button>
                )
            }
        }
        return null
    }

    function renewBtn(item) {
        if (item.owner_quot_id.is_show_renew) {
            if (!item.owner_quot_id.vendor_confirm_renewal) {
                return (
                    <button type="button" className="btn owner_btn"
                            data-toggle="modal"
                            data-target="#vendor_contract_renewal_confirm"
                            onClick={() => {
                                setItemId(item.id)
                            }}>
                        {gettext("Renewal")}
                    </button>
                )
            }
        }
        return null
    }

    function desktopLayout(item) {
        return (
            <>
                <div className="card-body body_bk_color_white contract_layout" key={"a" + item.owner_quot_id.id}>
                    <div className="row">
                        <div className="col-2">
                            <span>{gettext("Contract")}</span>
                            <span>
                                {item.state === 0 &&
                                <span className="vendor_main_title_color2">({gettext("Submitted")})</span>}
                                {item.state === 1 && !item.owner_quot_id.contract_accept &&
                                <span className="vendor_main_title_color2">({gettext("Waiting approve")})</span>}
                                {item.state === 1 && item.owner_quot_id.contract_accept &&
                                <span className="vendor_main_title_color2">({gettext("In progress")})</span>}
                                {item.state === 1 && item.owner_quot_id.contract_accept && contract_end_day(item.owner_quot_id.date_to)}
                            </span>
                        </div>
                        <div className="col-5">{item.owner_quot_id.property.name}</div>
                        <div
                            className="col-5 text-right">({item.owner_quot_id.date_from} - {item.owner_quot_id.date_to})
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">{gettext("File")}</div>
                        <div className="col-10">
                            {fileDownloadFROMURL(item.id, item.owner_quot_id)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">{gettext("Remark")}</div>
                        <div className="col-10">{item.owner_quot_id.remark}</div>
                    </div>

                </div>
                <div className="card-body body_bk_color_white contract_layout mt-1" key={item.id}>
                    {/*<div className="row">*/}
                    {/*    <div className="col-8">*/}
                    {/*        {item.state === 0 &&*/}
                    {/*        <span className="vendor_main_title_color2">({gettext("Submitted")})</span>}*/}
                    {/*        {item.state === 1 && !item.owner_quot_id.contract_accept &&*/}
                    {/*        <span className="vendor_main_title_color2">({gettext("Waiting approve")})</span>}*/}
                    {/*        {item.state === 1 && item.owner_quot_id.contract_accept &&*/}
                    {/*        <span className="vendor_main_title_color2">({gettext("In progress")})</span>}*/}
                    {/*        {item.state === 1 && item.owner_quot_id.contract_accept && contract_end_day(item.owner_quot_id.date_to)}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row">
                        <div className="col-2">{gettext("Price")}</div>
                        <div className="col-6">
                            <small>{money_format(item.price)}</small>
                        </div>
                        <div className="col-4 text-right">
                            <small>{Moment(item.created_at).format('YYYY-MM-DD hh:mm a')}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">{gettext("Remark")}</div>
                        <div className="col-5">{item.remark}</div>
                        <div className="col-5 text-right">
                            {item.state === 1 && item.owner_quot_id.contract_accept &&
                            <a className="btn owner_btn"
                               href={"/vendor/contract/detail/" + item.id}>{gettext("Maintenance Records")}</a>}
                        </div>
                    </div>
                    {item.owner_quot_id.contract_accept &&
                    <div className="row">
                        <div className="col-12">
                            {item.state === 1 && item.owner_quot_id.vendor_cancel && !item.owner_quot_id.owner_cancel &&
                            <span className="text-danger">{gettext("Vendor is applying canceling contract")}</span>}
                            {item.state === 1 && item.owner_quot_id.owner_cancel && !item.owner_quot_id.vendor_cancel &&
                            <span className="text-danger">{gettext("Owner is applying canceling contract")}</span>}
                            {item.state === 4 &&
                            <span className="text-danger">{gettext("Contract cancelled")}</span>}
                            {!item.owner_quot_id.owner_confirm_renewal && item.owner_quot_id.vendor_confirm_renewal &&
                            <span
                                className="text-danger">{gettext("Waiting owner approve renew contract")}</span>}
                            {item.owner_quot_id.owner_confirm_renewal && !item.owner_quot_id.vendor_confirm_renewal &&
                            <span
                                className="text-danger">{gettext("Owner is applying renew contract")}</span>}
                            {item.owner_quot_id.owner_confirm_renewal && item.owner_quot_id.vendor_confirm_renewal && !item.owner_quot_id.contract_renewed &&
                            <span
                                className="text-danger">{gettext("Waiting HoHoLink approve the new contract")}</span>}
                            {item.state === 1 &&
                            <div className="float-right">
                                {cancelBtn(item)}
                                {renewBtn(item)}
                            </div>
                            }
                        </div>
                    </div>
                    }
                </div>
            </>
        )
    }

    function desktopDeadLineLayout(item) {
        return (
            <>
                <div className="card-body body_bk_color_white contract_layout" key={"a" + item.owner_quot_id.id}>
                    <div className="row">
                        <div className="col-2">
                            <span>{gettext("Contract")}</span>
                            <span>
                                <span className="vendor_main_title_color2">({gettext("Contract had expired")})</span>
                            </span>
                        </div>
                        <div className="col-5">{item.owner_quot_id.property.name}</div>
                        <div
                            className="col-5 text-right">({item.owner_quot_id.date_from} - {item.owner_quot_id.date_to})
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">{gettext("File")}</div>
                        <div className="col-10">
                            {fileDownloadFROMURL(item.id, item.owner_quot_id)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">{gettext("Remark")}</div>
                        <div className="col-10">{item.owner_quot_id.remark}</div>
                    </div>
                </div>
                <div className="card-body body_bk_color_white contract_layout mt-1" key={item.id}>
                    <div className="row">
                        <div className="col-2">{gettext("Price")}</div>
                        <div className="col-6">
                            <small>{money_format(item.price)}</small>
                        </div>
                        <div className="col-4 text-right">
                            <small>{Moment(item.created_at).format('YYYY-MM-DD hh:mm a')}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">{gettext("Remark")}</div>
                        <div className="col-5">{item.remark}</div>
                        <div className="col-5 text-right">
                            {item.state === 1 && item.owner_quot_id.contract_accept &&
                            <a className="btn owner_btn"
                               href={"/vendor/contract/detail/" + item.id}>{gettext("Maintenance Records")}</a>}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function mobileLayout(item) {
        return (
            <>
                <div key={"a" + item.owner_quot_id.id}>
                    <div className="member_price_bk p-1">
                        <span>{item.owner_quot_id.property.name}</span>
                    </div>

                    <div className="card-body body_bk_color_white">
                        <div className="row">
                            <div className="col title_size">
                                <span>{gettext("Contract")}</span>
                                {item.state === 0 &&
                                <span className="vendor_main_title_color2">({gettext("Submitted")})</span>}
                                {item.state === 1 && !item.owner_quot_id.contract_accept &&
                                <span className="vendor_main_title_color2">({gettext("Waiting approve")})</span>}
                                {item.state === 1 && item.owner_quot_id.contract_accept &&
                                <span className="vendor_main_title_color2">({gettext("In progress")})</span>}
                                {item.state === 1 && item.owner_quot_id.contract_accept && contract_end_day(item.owner_quot_id.date_to)}
                            </div>
                            <div className="col">{item.owner_quot_id.date_from} - {item.owner_quot_id.date_to}</div>
                        </div>

                        <div className="row">
                            <div className="col title_size">{gettext("File")}</div>
                            <div className="col">
                                {fileDownloadFROMURL(item.id, item.owner_quot_id)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col title_size">{gettext("Remark")}</div>
                            <div className="col">{item.owner_quot_id.remark}</div>
                        </div>
                    </div>
                </div>
                <div className="mt-1" key={item.id}>
                    <div className="card-body body_bk_color_white">
                        {/*<div className="row">*/}
                        {/*    <div className="col">*/}
                        {/*        {item.state === 0 &&*/}
                        {/*        <span className="vendor_main_title_color2">({gettext("Submitted")})</span>}*/}
                        {/*        {item.state === 1 && !item.owner_quot_id.contract_accept &&*/}
                        {/*        <span className="vendor_main_title_color2">({gettext("Waiting approve")})</span>}*/}
                        {/*        {item.state === 1 && item.owner_quot_id.contract_accept &&*/}
                        {/*        <span className="vendor_main_title_color2">({gettext("In progress")})</span>}*/}
                        {/*        {item.state === 1 && item.owner_quot_id.contract_accept && contract_end_day(item.owner_quot_id.date_to)}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="row">
                            <div className="col title_size">{gettext("Price")}</div>
                            <div className="col">{money_format(item.price)}</div>
                        </div>
                        <div className="row">
                            <div className="col title_size">{gettext("Remark")}</div>
                            <div className="col">{item.remark}</div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {item.state === 1 && item.owner_quot_id.contract_accept &&
                                <a className="btn owner_btn shadow"
                                   href={"/vendor/contract/detail/" + item.id}>{gettext("Maintenance Records")}</a>}
                            </div>
                        </div>
                        {item.owner_quot_id.contract_accept &&
                        <div className="row">
                            <div className="col-12">
                                {item.state === 1 && item.owner_quot_id.vendor_cancel && !item.owner_quot_id.owner_cancel &&
                                <span className="text-danger">{gettext("Vendor is applying canceling contract")}</span>}
                                {item.state === 1 && item.owner_quot_id.owner_cancel && !item.owner_quot_id.vendor_cancel &&
                                <span className="text-danger">{gettext("Owner is applying canceling contract")}</span>}
                                {item.state === 4 &&
                                <span className="text-danger">{gettext("Contract cancelled")}</span>}
                                {item.state === 1 && !item.owner_quot_id.vendor_cancel && !item.owner_quot_id.owner_cancel &&
                                <div className="float-right">
                                    {!item.owner_quot_id.vendor_cancel && !item.owner_quot_id.owner_cancel &&
                                    <button type="button" className="btn login_btn"
                                            data-toggle="modal"
                                            data-target="#vendor_contract_cancel_confirm"
                                            onClick={() => {
                                                setItemId(item.id)
                                            }}>
                                        {gettext("Cancel")}
                                    </button>
                                    }
                                    {item.owner_quot_id.is_show_renew && !item.owner_quot_id.owner_confirm_renewal && !item.owner_quot_id.owner_confirm_renewal &&
                                    <button type="button" className="btn login_btn"
                                            data-toggle="modal"
                                            data-target="#vendor_contract_renewal_confirm"
                                            onClick={() => {
                                                setItemId(item.id)
                                            }}>
                                        {gettext("Renewal")}
                                    </button>
                                    }
                                </div>
                                }
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </>
        )
    }

    function mobileDeadLineLayout(item) {
    return (
        <>
            <div key={"a" + item.owner_quot_id.id}>
                <div className="member_price_bk p-1">
                    <span>{item.owner_quot_id.property.name}</span>
                </div>
                <div className="card-body body_bk_color_white">
                    <div className="row">
                        <div className="col title_size">
                            <span>{gettext("Contract")}</span>
                            <span className="vendor_main_title_color2">({gettext("Contract has expired")})</span>
                        </div>
                        <div className="col">{item.owner_quot_id.date_from} - {item.owner_quot_id.date_to}</div>
                    </div>

                    <div className="row">
                        <div className="col title_size">{gettext("File")}</div>
                        <div className="col">
                            {fileDownloadFROMURL(item.id, item.owner_quot_id)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col title_size">{gettext("Remark")}</div>
                        <div className="col">{item.owner_quot_id.remark}</div>
                    </div>
                </div>
            </div>
            <div className="mt-1" key={item.id}>
                <div className="card-body body_bk_color_white">
                    <div className="row">
                        <div className="col title_size">{gettext("Price")}</div>
                        <div className="col">{money_format(item.price)}</div>
                    </div>
                    <div className="row">
                        <div className="col title_size">{gettext("Remark")}</div>
                        <div className="col">{item.remark}</div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {item.state === 1 && item.owner_quot_id.contract_accept &&
                            <a className="btn owner_btn shadow"
                               href={"/vendor/contract/detail/" + item.id}>{gettext("Maintenance Records")}</a>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

    function submitFinishLayout() {
        return (
            <div className="modal" id="vendor_property_submit_finish" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{gettext("Submit the service")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div>{gettext("Rank")}:<span className="ml-3">{rank}</span>/5</div>
                            <div className="pl-3 pr-3 pb-3">
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(1)}>
                                    <i className={rank >= 1 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(2)}>
                                    <i className={rank >= 2 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(3)}>
                                    <i className={rank >= 3 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(4)}>
                                    <i className={rank >= 4 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                                <button className="btn btn-default btn-grey btn-sm" onClick={() => setRankBtn(5)}>
                                    <i className={rank >= 5 ? "fas fa-star active_star_color" : "fas fa-star"}/>
                                </button>
                            </div>
                            <div>
                                <textarea className="form-control" placeholder={gettext("comment")} value={comment}
                                          rows="3"
                                          onChange={(event) => setComment(event.target.value)}></textarea>
                            </div>
                            <div className="text-danger font-weight-bold">
                                {!editRank && submitForm && gettext("Please give a rank and comment")}
                            </div>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn login_btn"
                                    onClick={() => {
                                        !loading && submit_data()
                                    }}>{gettext("Submit")}</button>
                            <button type="button" className="btn login_btn"
                                    data-dismiss="modal">{gettext("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {confirmCancelLayout()}
            {confirmRenewalLayout()}
            {submitFinishLayout()}
            <div>
                {data.length > 0 && data.map((item) => {
                        return (
                            <section className="card mt-3 body_bk_color_dark_white" key={item.id}>
                                {isMobileOnly ? mobileLayout(item) : desktopLayout(item)}
                            </section>
                        )
                    })
                }
                {
                    data.length === 0 && <div className="text-center p-3"><img src={nodata} height="50px"/>
                        <div>No data</div>
                    </div>
                }
                {loading && <div className="text-center"><img src={loading_image}/></div>}
            </div>
        </>
    )
}

let element = document.getElementById('vendor_contract');
if (typeof (element) != 'undefined' && element != null) {
    ReactDOM.render(<VendorContractBarLayout/>, document.getElementById('vendor_contract'));
}
import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {isMobileOnly} from 'react-device-detect';
import {get_data_fetch} from "./func_fetch";
import {func_getCookie} from "./func_getCookie";
import Moment from "node_modules_path/moment";
import nodata from "../../img/nodata.png";
import loading_image from "../../img/loading.svg";

function VendorBarLayout() {
    //country and cities data
    const [data, setData] = useState([]);
    const [index, setIndex] = useState(0);
    const [previous, setPrevious] = useState([]);
    const [next, setNext] = useState([]);
    const [count, setCount] = useState([]);
    const [current_action, setCurrent_action] = useState("");
    const [loading, setLoading] = useState(true);
    let url_link = "http://" + window.location.host;
    let offset = 10;


    useEffect(() => {
        (async () => {
            let url = new URL(window.location.href);
            let action = url.searchParams.get("action");
            if (action != null) {
                setCurrent_action(action);
            } else {
                setCurrent_action("pending");
            }
            setLoading(true);
            //api data
            let data_list = await get_data_fetch(
                url_link + '/api/quot_offer_list_filter/',
                "POST",
                {action: action}
            );
            setLoading(false);
            setData(data_list.results);
            setCount(data_list.count);
            setPrevious(data_list.previous);
            setNext(data_list.next);
        })();
    }, []);


    async function get_data(prop, action) {
        if (action != null) {
            setLoading(true);
            let url = new URL(action);
            let next_offset = url.searchParams.get("offset");
            setIndex(next_offset / offset);
            let data_list = await get_data_fetch(
                action == null ? "#" : action,
                "POST",
                {index: index}
            );
            setLoading(false);
            setData(data_list.results);
            setCount(data_list.count);
            setPrevious(data_list.previous);
            setNext(data_list.next);
        }
    }

    function pagination() {
        let cur_index = 0;
        let table = [];
        for (let i = 0; i < count; i = i + offset) {
            table.push(
                <li key={i} className={cur_index == index ? "page-item ml-1 mr-1 active" : "page-item ml-1 mr-1"}>
                    <div className="page-link" onClick={() => {
                        get_data(null, url_link + '/api/quot_offer_list_filter/?offset=' + i)
                    }}>{cur_index + 1}</div>
                </li>)
            cur_index++;
        }
        return table
    }


    function desktopLayout(item) {
        return (
            <section className="card mt-3 card_custom_radius2" key={item.id}>
                <div className="member_price_bk p-1">
                    {/*<span className="float-left pl-4">{item.id}</span>*/}
                    <span className="float-right pr-4">
                            {Moment(item.created_at).format('YYYY-MM-DD hh:mm a')}
                        </span>
                </div>
                <div className="card-body body_bk_color_white">
                    <div className="row">
                        <div className="col">
                            <div className="title_size">{gettext("Property")}</div>
                            <div>{item.property.name}</div>
                        </div>
                        <div className="col">
                            <div className="title_size">{gettext("Location")}</div>
                            <div>{item.property && `${item.property.country.name} / ${item.property.city.name}`}</div>
                        </div>
                        <div className="col">
                            <div className="title_size">{gettext("Select service")}</div>
                            <div>
                                {item.parent_service.name}
                                {item.service && "/ " + item.service.name}
                                {item.service2 && "/ " + item.service2.name}
                            </div>
                        </div>
                        <div className="col">
                            <div className="title_size">{gettext("Start date")}</div>
                            <div>{item.date}</div>
                        </div>
                        <div className="col">
                            <div className="title_size">Token</div>
                            <div>1</div>
                        </div>
                        <div className="col">
                            <a className="btn owner_btn"
                               href={"/vendor/quot_lists_filter/detail/" + item.id}>{gettext("Detail")}</a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    function mobileLayout(item) {
        return (
            <section className="card mt-3 card_custom_radius2" key={item.id}>
                <div className="member_price_bk p-1">
                    {/*<span className="float-left pl-4">{item.id}</span>*/}
                    <span className="float-right pr-4">
                         {Moment(item.created_at).format('YYYY-MM-DD hh:mm a')}
                    </span>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col">{gettext("Property")}</div>
                        <div className="col">{item.property.name}</div>
                    </div>
                    <div className="row">
                        <div className="col">{gettext("Location")}</div>
                        <div className="col">{item.property.country.name} / {item.property.city.name}</div>
                    </div>
                    <div className="row">
                        <div className="col">{gettext("Select service")}</div>
                        <div className="col">
                            {item.parent_service.name}
                            {item.service && "/ " + item.service.name}
                            {item.service2 && "/ " + item.service2.name}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">{gettext("Start date")}</div>
                        <div className="col">{item.date}</div>
                    </div>
                    <div className="row">
                        <div className="col">Token</div>
                        <div className="col">1</div>
                    </div>
                    <div className="row">
                        <div className="col"></div>
                        <div className="col">
                            <a className="btn owner_btn shadow" href={"/vendor/quot_lists_filter/detail/" + item.id}>
                                {gettext("Detail")}
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <>
            <div
                className="row text-center border border-warning price_title btn-group btn-group-toggle owner_bar_border"
                data-toggle="buttons">
                <label className={current_action === "pending" ? "col-4 btn ml-0 active" : "col-4 btn ml-0"}
                       onClick={() => {
                           location.href = '/vendor/quot_lists_filter/?action=pending';
                       }}>
                    <input type="radio"
                           name="options"
                           id="option1"
                           autoComplete="off"
                           defaultChecked={current_action === "pending" ? "defaultChecked" : ""}/>
                           {gettext("Submitted")}
                </label>

                <label className={current_action === "trading" ? "col-4 btn ml-0 active" : "col-4 btn ml-0"}
                       onClick={() => {
                           location.href = url_link + '/vendor/quot_lists_filter/?action=trading';
                       }}>
                    <input type="radio"
                           name="options"
                           id="option1"
                           autoComplete="off"
                           defaultChecked={current_action === "trading" ? "defaultChecked" : ""}/>
                           {gettext("In progress")}
                </label>

                <label className={current_action === "finish" ? "col-4 btn ml-0 active" : "col-4 btn ml-0"}
                       onClick={() => {
                           location.href = url_link + '/vendor/quot_lists_filter/?action=finish';
                       }}>
                    <input type="radio"
                           name="options"
                           id="option1"
                           autoComplete="off"
                           defaultChecked={current_action === "finish" ? "defaultChecked" : ""}/>
                           {gettext("Completed")}
                </label>

                {/*<label className={current_action === "cancel" ? "col-3 btn ml-0 active" : "col-3 btn ml-0"}*/}
                {/*       onClick={() => {*/}
                {/*           location.href = url_link + '/vendor/quot_lists_filter/?action=cancel';*/}
                {/*       }}>*/}
                {/*    <input type="radio" name="options" id="option1" autoComplete="off"/>{gettext("Canceled")}*/}
                {/*</label>*/}
            </div>
            <div>
                {data.length > 0 && data.map((item) => {
                    return (isMobileOnly ? item.property && mobileLayout(item) : item.property && desktopLayout(item))
                })}
                {
                    data.length == 0 && <div className="text-center p-3"><img src={nodata} height="50px"/>
                        <div>{gettext("No data")}</div>
                    </div>
                }
            </div>
            <ul className="pagination justify-content-center mt-3">
                <li className="page-item">
                    <div className="page-link" aria-label="Previous" onClick={() => {
                        get_data(null, previous)
                    }}>
                        <span aria-hidden="true">&laquo;</span>
                    </div>
                </li>
                {pagination()}
                <li className="page-item">
                    <div className="page-link" aria-label="Next" onClick={() => {
                        get_data(null, next)
                    }}>
                        <span aria-hidden="true">&raquo;</span>
                    </div>
                </li>
            </ul>
            {loading && <div className="text-center"><img src={loading_image}/></div>}
        </>
    )
}

let element = document.getElementById('vendor_quot_filter');
if (typeof (element) != 'undefined' && element != null) {
    ReactDOM.render(<VendorBarLayout/>, document.getElementById('vendor_quot_filter'));
}

